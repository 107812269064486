import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../../../ui/atoms/InputField';
import '../../../../styles/timePicker.css';
import { useHistory } from 'react-router-dom';
import { Buttons } from '../../../ui/atoms/Button';
import { useLazyQuery } from '@apollo/client';
import { IS_DEAL_NAME_EXISTS } from '../../../../queries/DealQueries';
import 'date-fns';
import { useDealForm } from '../../../../contexts/DealFormContext';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import {
    getTimeFromUtcDatetime,
    getDateFromUTCDateTime,
    combineDateAndTime,
} from '../../../../helpers/DateTimeHelpers.helpers';

const AddNewDealGeneralView = () => {
    const history = useHistory();
    const { formData, updateFormData } = useDealForm(); // Use the hook to access context
    const [checkDealNameExists, { loading, data }] = useLazyQuery(IS_DEAL_NAME_EXISTS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
    });

    const checkDealNameUnique = async (dealName: string) => {
        try {
            const { data } = await checkDealNameExists({ variables: { dealName } });
            return !data.isDealNameExists;
        } catch (error) {
            console.error('Error checking deal name uniqueness:', error);
            return false;
        }
    };
    const currentUtcDatetime = new Date().toISOString();
    const nowTime = getTimeFromUtcDatetime(currentUtcDatetime);
    const nowDate = getDateFromUTCDateTime(currentUtcDatetime);
    const today = new Date().toISOString().split('T')[0];

    const validationSchema = Yup.object().shape({
        dealName: Yup.string()
            .max(50, 'Deal name cannot exceed 50 characters')
            .required('Deal name is required')
            .test('unique', 'Deal name must be unique', async function (value) {
                if (!value) return false;
                const isUnique = await checkDealNameUnique(value);
                return isUnique;
            }),
        percentage: Yup.number()
            .typeError('Deal percentage must be a number')
            .min(0, 'Deal percentage cannot be less than 0')
            .max(100, 'Deal percentage cannot be more than 100')
            .required('Deal percentage is required'),
        startDate: Yup.date()
            .min(new Date().toISOString().split('T')[0], 'Start date cannot be in the past')
            .required('Start date is required'),
        startTime: Yup.string()
            .required('Start time is required')
            .test('future-time', 'Start time cannot be in the past', function (value) {
                const { startDate, startTime } = this.parent;
                const selectedStartDate = getOnlyDate(startDate);
                if (selectedStartDate) {
                    //const nowDate = getOnlyDate(new Date());
                    if (selectedStartDate === nowDate) {
                        //const nowTime = getOnlyTime(new Date());
                        return startTime >= nowTime;
                    }
                    return true;
                }
                return true;
            }),
        endDate: Yup.date()
            .min(Yup.ref('startDate'), 'End date cannot be before the start date')
            .required('End date is required'),
        endTime: Yup.string()
            .required('End time is required')
            .test('future-time', 'End time cannot be before the start time.', function (value) {
                const { startDate, startTime, endDate, endTime } = this.parent;
                const selectedStartDate = getOnlyDate(startDate);
                const selectedEndtDate = getOnlyDate(endDate);
                if (selectedStartDate === selectedEndtDate) {
                    if (endTime < startTime) {
                        return false;
                    }
                    return true;
                } else {
                    return true;
                }
            }),
        roundBoundary: Yup.string(),
        decimalPlaces: Yup.string().when('roundBoundary', {
            is: (roundBoundary) => roundBoundary !== undefined && roundBoundary !== '',
            then: Yup.string()
                .required('Rounding off to a decimal place is required')
                .oneOf(
                    ['WHOLE_NUMBER', 'FIRST_DECIMAL', 'SECOND_DECIMAL'],
                    'Invalid decimal place',
                ),
            otherwise: Yup.string(),
        }),
    });

    const getOnlyDate = (input: Date | string): string => {
        if (!input) return ''; // Return empty string for undefined input

        const date = typeof input === 'string' ? new Date(input) : input;

        // Check if date is valid
        if (isNaN(date.getTime())) return ''; // Return empty string for invalid dates

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const getOnlyTime = (input: Date | string): string => {
        const date = typeof input === 'string' ? new Date(input) : input;
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const boundaryOptions = [
        { key: 'upper', value: 'UPPER_BOUNDARY', text: 'UPPER BOUNDARY' },
        { key: 'lower', value: 'LOWER_BOUNDARY', text: 'LOWER BOUNDARY' },
    ];

    const decimalOptions = [
        { key: 'whole', value: 'WHOLE_NUMBER', text: 'WHOLE NUMBER (0)' },
        { key: 'first', value: 'FIRST_DECIMAL', text: '1ST DECIMAL PLACE (0.0)' },
        { key: 'second', value: 'SECOND_DECIMAL', text: '2ND DECIMAL PLACE (0.00)' },
    ];

    return (
        <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                updateFormData(values);
                history.push('/deals/select-partners');
            }}
        >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} className="h-full flex flex-col justify-evens">
                    <div className="text-lg font-poppins font-bold px-8">Deal Details</div>
                    <div className="grid grid-cols-8 gap-4 mt-8 px-8">
                        <div className="col-start-1 col-end-4">
                            <InputField
                                id="dealName"
                                placeHolder={'Deal Name'}
                                name="Deal Name"
                                labelWidth="w-21"
                                onChange={handleChange}
                                value={values.dealName}
                            />
                            {errors.dealName && touched.dealName ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.dealName}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-start-1 col-end-4">
                            <InputField
                                id="percentage"
                                placeHolder={'Percentage (%)'}
                                name="Percentage (%)"
                                labelWidth="w-21"
                                onChange={handleChange}
                                value={values.percentage}
                            />
                            {errors.percentage && touched.percentage ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.percentage}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-start-1 col-end-4">
                            <InputField
                                id="startDate"
                                placeHolder={'Start Date'}
                                name="Start Date"
                                labelWidth="w-21"
                                type="date"
                                onChange={handleChange}
                                value={values.startDate}
                                min={today}
                            />
                            {errors.startDate && touched.startDate ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.startDate}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-start-4 col-end-7">
                            <InputField
                                id="startTime"
                                placeHolder={'Start Time UTC'}
                                name="Start Time UTC"
                                labelWidth="w-21"
                                type="time"
                                onChange={handleChange}
                                value={values.startTime}
                            />
                            {errors.startTime && touched.startTime ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.startTime}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-start-1 col-end-4">
                            <InputField
                                id="endDate"
                                placeHolder={'End Date'}
                                name="End Date"
                                labelWidth="w-21"
                                type="date"
                                onChange={handleChange}
                                value={values.endDate}
                                min={today}
                            />
                            {errors.endDate && touched.endDate ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.endDate}
                                </div>
                            ) : null}
                        </div>

                        <div className="col-start-4 col-end-7">
                            <InputField
                                id="endTime"
                                placeHolder={'End Time UTC'}
                                name="End Time UTC"
                                labelWidth="w-21"
                                type="time"
                                onChange={handleChange}
                                value={values.endTime}
                            />
                            {errors.endTime && touched.endTime ? (
                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                    {errors.endTime}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <hr className="px-8 border mt-5" />
                    <div className="h-full flex flex-col  justify-evens">
                        <div className="text-lg font-poppins font-bold pt-8 px-8">
                            Final Price Adjustment
                        </div>
                        <div className="grid grid-cols-8 gap-4 py-2 w-full px-10">
                            <div className="col-start-1 col-end-4">
                                <div className="flex flex-wrap flex-col relative w-full">
                                    <div className="text-gray-500 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Round off Boundary
                                    </div>
                                    <select
                                        id="roundBoundary"
                                        name="roundBoundary"
                                        value={values.roundBoundary}
                                        onChange={handleChange}
                                        className="px-4 py-3 rounded-xl outline-none border-2 bg-white border-gray-400 hover:border-purple-500 w-full"
                                    >
                                        <option value="" label="Select Boundary" />
                                        {boundaryOptions.map((option) => (
                                            <option
                                                key={option.key}
                                                value={option.value}
                                                label={option.text}
                                            />
                                        ))}
                                    </select>
                                </div>
                                {errors.roundBoundary && touched.roundBoundary ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.roundBoundary}
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-start-4 col-end-7">
                                <div className="flex flex-wrap flex-col relative w-full">
                                    <div className="text-gray-500 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Decimal Places
                                    </div>
                                    <select
                                        id="decimalPlaces"
                                        name="decimalPlaces"
                                        value={values.decimalPlaces}
                                        onChange={handleChange}
                                        className="px-4 py-3 rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500 w-full"
                                        disabled={!values.roundBoundary}
                                    >
                                        <option value="" label="Select Decimal Places" />
                                        {decimalOptions.map((option) => (
                                            <option
                                                key={option.key}
                                                value={option.value}
                                                label={option.text}
                                            />
                                        ))}
                                    </select>
                                </div>
                                {errors.decimalPlaces && touched.decimalPlaces ? (
                                    <div className="text-red-500 text-xs mt-1 md:text-sm">
                                        {errors.decimalPlaces}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 pt-1 pb-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                            <Buttons
                                name="Cancel"
                                type="button"
                                buttonType="secondary-border-black"
                                id="cancel-button"
                                size="e-small"
                                other="mr-3"
                                onclick={() => history.push('/deals')}
                            />
                            <Buttons
                                name={'Continue'}
                                type="submit"
                                buttonType="primary"
                                id="save-button"
                                size="e-small"
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default AddNewDealGeneralView;
