import { useEffect, useState } from 'react';
import TreeNode from './TreeNode';
import SearchBar from '../../../ui/molecules/SearchBar';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_DEALS, GET_DEAL_ITEMS, SAVE_DEAL } from '../../../../queries/DealQueries';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../../../utils/loader';
import { Buttons } from '../../../ui/atoms/Button';
import { useHistory } from 'react-router-dom';
import { ERROR_ADD_DEAL, SUCCESS_MESSAGE_SAVED_DEAL } from '../../../../constants/deal';
import NormalLoader from '../../../../utils/normalLoader';
import Toast from '../../../ui/atoms/Toast';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import { useDealForm } from '../../../../contexts/DealFormContext';
import { PaymentMethod } from '../../../../enums/payment';
import { combineDateAndTime } from '../../../../helpers/DateTimeHelpers.helpers';

const CATALOG_PREFIX = 'catalog--';
const CATEGORY_PREFIX = 'category--';
const GROUP_PREFIX = 'group--';
const ITEM_PREFIX = 'item--';

export default function SelectItems() {
    const history = useHistory();
    const [treeContent, setTreeContent] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const { formData, updateFormData } = useDealForm();
    const [selectedContent, setSelectedContent] = useState(formData.selectedItems);

    const { data: productGroupQueryData, loading } = useQuery(GET_DEAL_ITEMS, {
        variables: {
            partnerIds: [...formData.selectedPartners],
            paymentChannelIds: formData.selectedPaymentChannels.map(
                (paymentChannel) => paymentChannel.id,
            ),
            paymentMethods: formData.selectedPaymentMethods,
            searchText: searchTerm,
            dealDateInput: {
                startDateTime: combineDateAndTime(formData.startDate, formData.startTime),
                endDateTime: combineDateAndTime(formData.endDate, formData.endTime),
            },
        },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            handleInitialCollapse(
                formatData(productGroupQueryData?.dealItemsByPartnerIdsAndPaymentChannelIds),
            );
            setTreeContent(
                formatData(productGroupQueryData?.dealItemsByPartnerIdsAndPaymentChannelIds),
            );
        },
    });

    const [saveDeal, { loading: saveDealLoading }] = useMutation(SAVE_DEAL, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_DEALS],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setShowToast(true);
            setMessage(SUCCESS_MESSAGE_SAVED_DEAL);
            setTimeout(() => {
                history.push('/deals');
            }, 2000);
        },
        onError: () => {
            setMessage(ERROR_ADD_DEAL);
            setShowToast(true);
            setError(true);
        },
    });

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    /**
     * This function formats the data to a structure that we can give to
     * the tree view component.
     */
    function formatData(data) {
        function getFormattedItems(items, catalogId, categoryId, partnerId) {
            if (items.length) {
                return items.map((item) => ({
                    id: `item--${catalogId}--${item.id}`,
                    name: item.itemName,
                    itemId: item.itemId,
                    categoryId: categoryId,
                    isAlreadyInDeal: item.isAlreadyInDeal,
                    sourceCurrency: item.sourceCurrency,
                    unitPrice: item.unitPrice,
                    itemPrimaryId: item.id,
                    partnerId: partnerId,
                }));
            } else {
                const errorMsg =
                    searchTerm?.length > 0
                        ? 'No products found the search'
                        : 'No products available for the selected channels';
                return [
                    {
                        id: `item--no--items`,
                        name: errorMsg,
                    },
                ];
            }
        }

        function getFormattedGroups(groups, catalogId, categoryId, partnerId) {
            return groups.map((group) => {
                return {
                    id: `group--${catalogId}--${group.id}`,
                    name: group.productGroupName,
                    children: getFormattedItems(group.items, catalogId, categoryId, partnerId),
                };
            });
        }

        function getFormattedCategories(categories, catalogId, partnerId) {
            return categories.map((category) => ({
                id: `category--${catalogId}--${category.id}`,
                name: category.categoryName,
                children: getFormattedGroups(
                    category.productGroups,
                    catalogId,
                    category?.id,
                    partnerId,
                ),
            }));
        }
        const catalogs = data.map((catalog) => {
            return {
                id: `catalog--${catalog.catalogId}`,
                name: catalog.catalogName,
                children: getFormattedCategories(catalog.categories, catalog.catalogId, catalog.id),
            };
        });
        return catalogs;
    }

    const handleInitialCollapse = (formattedData) => {
        if (formattedData.length > 0) {
            const firstCatalog = formattedData[0];

            const catalogId = firstCatalog.id;

            const categoryIds = [];
            const groupIds = [];
            const itemIds = [];

            firstCatalog.children.forEach((category) => {
                categoryIds.push(category.id);
                category.children.forEach((group) => {
                    groupIds.push(group.id);
                    group.children.forEach((item) => {
                        itemIds.push(item.id);
                    });
                });
            });
            setExpandedNodes([...categoryIds, ...groupIds, ...itemIds, catalogId]);
        }
    };

    /**
     * This function searches through the tree to find a specific item.
     * @param nodeType either 'catalog' or 'category' or 'group'
     * @param nodeId id of the target
     * @returns the target object
     */
    const findInTree = (nodeType, nodeId) => {
        let found = null;

        // Iterate catalogs
        for (let i = 0; i < treeContent.length; i++) {
            const catalog = treeContent[i];
            if (nodeType === 'catalog' && catalog.catalogId === nodeId) {
                found = catalog;
                break;
            }

            // Iterate categories
            for (let j = 0; j < catalog.children.length; j++) {
                const category = catalog.children[j];
                if (nodeType === 'category' && category.id === nodeId) {
                    found = category;
                    break;
                }

                // Iterate groups
                for (let k = 0; k < category.children.length; k++) {
                    const group = category.children[k];
                    if (nodeType === 'group' && group.id === nodeId) {
                        found = group;
                        break;
                    }
                }

                if (found) break;
            }

            if (found) break;
        }

        return found;
    };

    // Returns an array containing the IDs of the group and its children
    const getGroupAndChildrenIds = (groupId) => {
        const selectedGroup = findInTree('group', groupId);
        const groupItemIds = selectedGroup.children.map((child) => child.id);
        return [groupId, ...groupItemIds];
    };

    // Returns an array containing the IDs of the category and its children
    const getCategoryAndChildrenIds = (categoryId) => {
        const selectedCategory = findInTree('category', categoryId);
        const categoryGroupIds = selectedCategory.children.map((child) => child.id);
        let childrenIds = [];
        categoryGroupIds.forEach((groupId) => {
            const groupAndChildrenIds = getGroupAndChildrenIds(groupId);
            childrenIds = childrenIds.concat(groupAndChildrenIds);
        });
        return [categoryId, ...childrenIds];
    };

    // Returns an array containing the IDs of the catalog and its children
    const getCatalogAndChildrenIds = (catalogId) => {
        const selectedCatalog = findInTree('catalog', catalogId);
        const catalogCategoryIds = selectedCatalog.children.map((child) => child.id);
        let childrenIds = [];
        catalogCategoryIds.forEach((categoryId) => {
            const categoryAndChildrenIds = getCategoryAndChildrenIds(categoryId);
            childrenIds = childrenIds.concat(categoryAndChildrenIds);
        });
        return [catalogId, ...childrenIds];
    };

    // Handles when the checkboxes are ticked/unticked
    const handleSelectToggle = (event, nodeId) => {
        setShowWarningModal(false);
        const checked = event.target.checked;

        if (checked) {
            if (nodeId.startsWith(ITEM_PREFIX)) {
                setSelectedContent((current) => [...current, nodeId]);
            } else if (nodeId.startsWith(GROUP_PREFIX)) {
                const groupAndChildrenIds = getGroupAndChildrenIds(nodeId);
                setSelectedContent((current) => [...current, ...groupAndChildrenIds]);
            } else if (nodeId.startsWith(CATEGORY_PREFIX)) {
                const categoryAndChildrenIds = getCategoryAndChildrenIds(nodeId);
                setSelectedContent((current) => [...current, ...categoryAndChildrenIds]);
            } else {
                const catalogAndChildrenIds = getCatalogAndChildrenIds(nodeId);
                setSelectedContent((current) => [...current, ...catalogAndChildrenIds]);
            }
        } else {
            if (nodeId.startsWith(ITEM_PREFIX)) {
                setSelectedContent((current) => current.filter((itemId) => itemId !== nodeId));
            } else if (nodeId.startsWith(GROUP_PREFIX)) {
                const groupAndChildrenIds = getGroupAndChildrenIds(nodeId);
                setSelectedContent((current) =>
                    current.filter((id) => !groupAndChildrenIds.includes(id)),
                );
            } else if (nodeId.startsWith(CATEGORY_PREFIX)) {
                const categoryAndChildrenIds = getCategoryAndChildrenIds(nodeId);
                setSelectedContent((current) =>
                    current.filter((id) => !categoryAndChildrenIds.includes(id)),
                );
            } else {
                const catalogAndChildrenIds = getCatalogAndChildrenIds(nodeId);
                setSelectedContent((current) =>
                    current.filter((id) => !catalogAndChildrenIds.includes(id)),
                );
            }
        }
    };

    const generateSearchSuggestions = (response) => {
        if (response?.length > 0) {
            // Extract all items
            const items = response.flatMap((partner) =>
                partner.categories.flatMap((category) =>
                    category.productGroups.flatMap((group) => group.items),
                ),
            );

            // Extract unique item names and IDs
            const uniqueItemNames = new Set();
            const uniqueItemIds = new Set();

            items.forEach((item) => {
                if (item.itemName) uniqueItemNames.add(item.itemName);
                if (item.itemId) uniqueItemIds.add(item.itemId);
            });

            // Convert sets to arrays
            const productNames = Array.from(uniqueItemNames);
            const productIds = Array.from(uniqueItemIds);

            // Combine item names and IDs into a single array
            const combinedArray = productNames.concat(productIds);

            return combinedArray;
        } else {
            return [];
        }
    };

    const generateDealActivePartnerItems = () => {
        const selectedItems = formData.selectedItems.filter(
            (selectedItem) =>
                selectedItem.startsWith(ITEM_PREFIX) &&
                !selectedItem.startsWith(`${ITEM_PREFIX}no--items`),
        );
        const distinctItems = [...new Map(selectedItems.map((item) => [item, item])).values()];

        const dealActivePartnerItems = distinctItems.map((item) => {
            const itemSplit = item.split('--');

            return { catalogId: itemSplit[1], itemId: itemSplit[2] };
        });

        return dealActivePartnerItems;
    };

    const generateDealPaymentChannel = () => {
        const dcbChannelsIds = formData.selectedPaymentChannels
            .filter(
                (paymentChannel) =>
                    paymentChannel.paymentMethod === PaymentMethod[PaymentMethod.DCB],
            )
            .map((paymentChannel) => paymentChannel.id);

        const pspChannelsIds = formData.selectedPaymentChannels
            .filter(
                (paymentChannel) =>
                    paymentChannel.paymentMethod === PaymentMethod[PaymentMethod.PSP],
            )
            .map((paymentChannel) => paymentChannel.id);

        const dealPaymentChannel = {
            isPspSelected: formData.selectedPaymentMethods.includes(
                PaymentMethod[PaymentMethod.PSP],
            ),
            isDcbSelected: formData.selectedPaymentMethods.includes(
                PaymentMethod[PaymentMethod.DCB],
            ),
            isPointsSelected: formData.selectedPaymentMethods.includes(
                PaymentMethod[PaymentMethod.POINTS],
            ),
            pspChannelIds: pspChannelsIds.length > 0 ? pspChannelsIds : null,
            dcbChannelIds: dcbChannelsIds.length > 0 ? dcbChannelsIds : null,
        };

        return dealPaymentChannel;
    };

    const handleSubmit = () => {
        setError(false);
        setShowWarningModal(false);

        const dealActivePartnerItems = generateDealActivePartnerItems();
        const dealPaymentChannel = generateDealPaymentChannel();

        if (dealActivePartnerItems?.length > 0) {
            saveDeal({
                variables: {
                    dealInput: {
                        dealName: formData.dealName,
                        dealPercentage: formData.percentage,
                        finalPriceRoundOffBoundary:
                            formData.roundBoundary != '' ? formData.roundBoundary : null,
                        finalPriceRoundOffDecimalPlace:
                            formData.decimalPlaces != '' ? formData.decimalPlaces : null,
                        startDateTime: combineDateAndTime(formData.startDate, formData.startTime),
                        endDateTime: combineDateAndTime(formData.endDate, formData.endTime),
                        selectedPartnerIds: [...formData.selectedPartners],
                        isActive: true,
                        dealActivePartnerItems: dealActivePartnerItems,
                        dealPaymentChannel: dealPaymentChannel,
                    },
                },
            });
        } else {
            setShowWarningModal(true);
        }
    };

    useEffect(() => {
        updateFormData({ selectedItems: selectedContent });
    }, [selectedContent]);

    return (
        <div className="w-full pl-10 pr-5">
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    width="w-8/12"
                    error={error}
                    selfDestruct={true}
                    selfDestructTimer={3000}
                />
            )}
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={'Please select at least one product.'}
                warningModalTitle={'Alert'}
            />
            <div className="text-lg font-poppins font-bold mb-5">Products</div>
            <div className="border-2 border-gray-300 space-y-5 p-2 py-5 rounded-md mb-20">
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500">
                        <SearchBar
                            id="deals-search-tems"
                            options={generateSearchSuggestions(
                                productGroupQueryData?.dealItemsByPartnerIdsAndPaymentChannelIds,
                            )}
                            handleChange={(e, newVal, reason) => {
                                setSearchClicked(true);
                                handleChange(e, newVal, reason);
                            }}
                            placeHolder={'Search by product name or id'}
                        />
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {treeContent?.length ? (
                            <>
                                {treeContent.map((node) => (
                                    <TreeNode
                                        key={node.id}
                                        node={node}
                                        onSelectToggle={handleSelectToggle}
                                        selectedContent={formData.selectedItems}
                                        expandedNodes={expandedNodes}
                                        setExpandedNodes={setExpandedNodes}
                                        findInTree={findInTree}
                                    />
                                ))}
                            </>
                        ) : (
                            <div>There are no items to display</div>
                        )}
                    </>
                )}
                <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                    <Buttons
                        name="Go Back"
                        type="button"
                        buttonType="secondary-border-black"
                        id="cancel-button"
                        size="e-small"
                        other="mr-3"
                        onclick={() => history.push('/deals/payment-channels')}
                    />
                    <Buttons
                        name={saveDealLoading ? <NormalLoader /> : 'Create Deal'}
                        type="submit"
                        buttonType="primary"
                        id="save-button"
                        size="e-small"
                        onclick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}
