/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Buttons } from '../../ui/atoms/Button';
import { InputField } from '../../ui/atoms/InputField';
import * as Yup from 'yup';
import '../../../styles/mutliselect.css';
import { useHistory } from 'react-router';
import UploadCategoryImage from '../categoryScreens/UploadCategoryImage';
import { useMutation } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import Toast from '../../ui/atoms/Toast';
import { Tooltip } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import NormalLoader from '../../../utils/normalLoader';
import UnsaveChangesWarning from '../../templates/modals/UnsaveChangesWarning';
import {
    PRODUCT_ADD_EXIT_CONFIRM_MESSAGE,
    PRODUCT_ADD_EXIT_CONFIRM_TITLE,
} from '../../../constants/product';
import { useDispatch } from 'react-redux';
import { RESET_SELECTED_ITEMS_DATA_TABLE } from '../../../enums/redux/redux-enum';
import ReactSwitch from 'react-switch';
import GroupItemSelectionView from './GroupItemSelectionView';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import {
    ADD_PRODUCT_GROUP_PAGE,
    ADD_PRODUCT_GROUP_PRODUCT_TYPE_CHANGE_WARNING_DESCRIPTION,
    ADD_PRODUCT_GROUP_PRODUCT_TYPE_CHANGE_WARNING_TITLE,
    ERROR_ADD_GROUP,
    SUCCESS_ADD_GROUP,
} from '../../../constants/productGroups';
import { ADD_PRODUCT_GROUPS, FETCH_PRODUCT_GROUPS } from '../../../queries/ProductGroupQueries';

export default function AddNewGroup() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [thumbNail, setThumbNail] = useState('');
    const [productType, setProductType] = useState<string>(null);
    const [addGroupLoading, setAddGroupLoading] = useState(false);
    const [isFeatured, setIsFeatured] = useState(true);
    const [coverImage, setCoverImage] = useState('');
    const [descriptionLong, setDescriptionLong] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const [uniqueTitleError, setUniqueTitleError] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);
    const [groupItems, setGroupItems] = useState([]);
    const [uniqueUrlMaskError, setUniqueUrlMaskError] = useState(false);

    const [saveGroup] = useMutation(ADD_PRODUCT_GROUPS, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_PRODUCT_GROUPS],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setMessage(SUCCESS_ADD_GROUP);
            console.log('Saved');
            setShowToast(true);
            setTimeout(() => {
                setAddGroupLoading(false);
                history.push('/products/groups');
            }, 2000);
        },
        onError: (error: any) => {
            setAddGroupLoading(false);
            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1032) {
                    setUniqueTitleError(true);
                }

                if (graphQLErrors[0].extensions.errorCode === 1037) {
                    if (graphQLErrors[0].extensions.errorMessage.includes('url mask')) {
                        setUniqueUrlMaskError(true);
                    }
                }
            } else {
                setMessage(ERROR_ADD_GROUP);
                setShowToast(true);
                setError(true);
            }
        },
    });

    const validationSchema = Yup.object({
        groupTitle: Yup.string().trim().required('Please enter a group Title'),
        displayName: Yup.string().trim().required('Please enter a display name'),
    });

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: 'white',
                    backgroundColor: '#9f7aea',
                },
                arrow: {
                    color: '#9f7aea',
                },
            },
        },
    });

    useEffect(() => {
        setIsDirty(true);
    }, []);

    useEffect(() => {
        return () => {
            dispatch({ type: RESET_SELECTED_ITEMS_DATA_TABLE });
        };
    }, []);

    return (
        <Formik
            initialValues={{
                groupTitle: '',
                displayName: '',
                urlMask: '',
                groupDescription: '',
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={({ groupTitle, urlMask, displayName }) => {
                setUniqueTitleError(false);
                if (groupTitle && !error) {
                    setAddGroupLoading(true);
                    setUniqueUrlMaskError(false);

                    const itemOrder = [...groupItems.map((groupItem) => groupItem.id)].join();

                    saveGroup({
                        variables: {
                            productGroup: {
                                name: groupTitle,
                                displayName: displayName,
                                description: descriptionLong,
                                thumbnail: thumbNail,
                                bannerImage: coverImage,
                                productType: itemOrder?.length > 0 ? productType : null,
                                featured: isFeatured,
                                urlMask: urlMask,
                                itemOrder: itemOrder?.length > 0 ? itemOrder : null,
                                categoryIds: null,
                            },
                        },
                    });
                }
            }}
        >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
                <>
                    {showToast && (
                        <Toast
                            setShowToast={setShowToast}
                            message={message}
                            width="w-10/12"
                            margin="ml-1"
                            error={error}
                        />
                    )}
                    <WarningMessageModal
                        showWarningModal={showWarningModal}
                        setShowWarningModal={setShowWarningModal}
                        warningModalBody={ADD_PRODUCT_GROUP_PRODUCT_TYPE_CHANGE_WARNING_DESCRIPTION}
                        warningModalTitle={ADD_PRODUCT_GROUP_PRODUCT_TYPE_CHANGE_WARNING_TITLE}
                        setProcessFinishedClicked={() => setShowWarningModal(false)}
                    />
                    <div className="pr-44 -ml-4">
                        <div className="grid grid-cols-8 gap-4">
                            <div className="col-start-1 col-end-9 w-full flex space-x-5">
                                <div className="mt-3 px-4 w-1/2">
                                    <InputField
                                        id="groupTitle"
                                        placeHolder="Enter Group title"
                                        name="Group Title"
                                        labelWidth="w-25"
                                        maxLength={255}
                                        onChange={(event) => {
                                            if (uniqueTitleError === true) {
                                                setUniqueTitleError(false);
                                            }
                                            handleChange(event);
                                        }}
                                        value={values.groupTitle}
                                    />
                                    {errors.groupTitle && touched.groupTitle ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.groupTitle}
                                        </div>
                                    ) : null}
                                    {uniqueTitleError ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            Group title already exists.
                                        </div>
                                    ) : null}
                                    {(errors.groupTitle && touched.groupTitle) || uniqueTitleError
                                        ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                        : null}
                                </div>
                                <div className="flex space-x-5 items-center">
                                    <span>Featured Group</span>
                                    <ReactSwitch
                                        id="featured"
                                        checked={isFeatured}
                                        onChange={(e: boolean) => {
                                            setIsFeatured(e);
                                        }}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor="#8b5cf6"
                                        height={24}
                                        width={46}
                                    />
                                </div>
                            </div>

                            <UnsaveChangesWarning
                                contentText={PRODUCT_ADD_EXIT_CONFIRM_MESSAGE}
                                messageTitle={PRODUCT_ADD_EXIT_CONFIRM_TITLE}
                                when={isDirty || window.onbeforeunload ? true : false}
                                navigate={(path) => history.push(path)}
                                shouldBlockNavigation={() => {
                                    if (isDirty) {
                                        return true;
                                    }
                                    return false;
                                }}
                                displayIcon={true}
                                displayIconName={'clarity_error-standard-line-svg.svg'}
                            />
                            <div className="col-start-1 col-end-9 w-full flex space-x-5">
                                <div className="mb-5 px-4 w-1/2">
                                    <InputField
                                        id="displayName"
                                        placeHolder="Enter display name"
                                        name="Display Name"
                                        labelWidth="w-25"
                                        maxLength={255}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        value={values.displayName}
                                    />
                                    {errors.displayName && touched.displayName ? (
                                        <div className="text-red-500 text-xs mt-1 md:text-sm">
                                            {errors.displayName}
                                        </div>
                                    ) : null}
                                    {errors.displayName && touched.displayName
                                        ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                        : null}
                                </div>
                            </div>

                            <div className="lg:col-start-1 lg:col-end-9">
                                <div className="w-full flex space-x-5 justify-center items-center">
                                    <div className="flex w-1/2 flex-wrap flex-col relative mt-0 px-4">
                                        <div className="text-gray-500 w-24 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                            Product Type
                                        </div>
                                        <select
                                            value={productType}
                                            className="p-4 lg:p-3 lg:py-4  rounded-xl outline-none border-2  bg-white border-gray-400 hover:border-purple-500"
                                            id="product-type"
                                            onChange={(e) => {
                                                if (groupItems?.length > 0) {
                                                    setShowWarningModal(true);
                                                } else {
                                                    setProductType(e.target.value);
                                                    setIsDirty(true);
                                                }
                                            }}
                                            onBlur={(e) => {
                                                if (groupItems?.length > 0) {
                                                    setShowWarningModal(true);
                                                } else {
                                                    setProductType(e.target.value);
                                                    setIsDirty(true);
                                                }
                                            }}
                                        >
                                            <option value={null} disabled selected>
                                                Select product type
                                            </option>
                                            <option value="VOUCHER">Voucher</option>
                                            <option value="TOPUP">Topup</option>
                                        </select>
                                    </div>
                                    <div className="px-4 w-1/2 flex flex-col items-start justify-start">
                                        <InputField
                                            id="urlMask"
                                            placeHolder="Enter URL Mask"
                                            name="URL Mask"
                                            labelWidth="w-25"
                                            maxLength={255}
                                            onChange={(event) => {
                                                if (uniqueUrlMaskError === true) {
                                                    setUniqueUrlMaskError(false);
                                                }
                                                handleChange(event);
                                            }}
                                            value={values.urlMask}
                                        />
                                        {errors.urlMask && touched.urlMask ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.urlMask}
                                            </div>
                                        ) : null}
                                        {uniqueUrlMaskError ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                Mask already exists
                                            </div>
                                        ) : null}
                                        {(errors.urlMask && touched.urlMask) || uniqueUrlMaskError
                                            ? window.scrollTo({ top: 0, behavior: 'smooth' })
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-start-1 col-end-9 mt-5 flex flex-wrap relative px-4">
                                <div
                                    className={`text-gray-500  w-36 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20`}
                                >
                                    Group Description
                                </div>
                                <textarea
                                    className="p-4 z-0 min-w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500"
                                    id="groupDescription"
                                    name="groupDescription"
                                    rows={5}
                                    cols={138}
                                    draggable={false}
                                    maxLength={600}
                                    onInput={handleChange('groupDescription')}
                                    onChange={(e) => {
                                        setDescriptionLong(e.target.value);
                                        setIsDirty(true);
                                    }}
                                    value={values?.groupDescription}
                                    placeholder="Enter group description"
                                />
                                <div className="w-full py-0.5 justify-end  flex  pl-4">
                                    <div className="font-poppins text-gray-400 mr-5">
                                        600 characters limited
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-5 flex-col xl:flex-row pl-4 pr-64">
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Group Thumbnail(Optional)
                                    </div>
                                    <div className="text-gray-400">(Max 100X 100)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="thumbnail"
                                    isThumbNail={true}
                                    setImage={setThumbNail}
                                    isThumbnailImage={true}
                                    setIsDirty={setIsDirty}
                                />

                                <div className="text-gray-400 mt-5 flex mb-5">
                                    What can I upload?
                                    <div
                                        className=" py-0.5   pl-4"
                                        onMouseOver={() => setShowToolTip(true)}
                                        onMouseLeave={() => setShowToolTip(false)}
                                    >
                                        <img
                                            className={`w-5 h-6 ${
                                                showToolTip ? 'hidden' : 'block'
                                            }`}
                                            src="/images/icons/question2.png"
                                            alt="question"
                                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => setShowToolTip(true)}
                                            onKeyDown={() => setShowToolTip(true)}
                                        />
                                        {showToolTip ? (
                                            <MuiThemeProvider theme={theme}>
                                                <Tooltip
                                                    key={2}
                                                    title={'Upload  (SVG)'}
                                                    arrow
                                                    placement="right-start"
                                                >
                                                    <img
                                                        className="w-5 h-6"
                                                        src="/images/icons/question2.png"
                                                        alt="question"
                                                    />
                                                </Tooltip>
                                            </MuiThemeProvider>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="flex my-3">
                                    <div className="mr-1 font-poppins">
                                        Group Cover Image (Optional)
                                    </div>
                                    <div className="text-gray-400"> (Max 2048 X 2048)</div>
                                </div>

                                <UploadCategoryImage
                                    width={'w-5/6'}
                                    height={'h-80'}
                                    isEdit={true}
                                    inputId="coverImage"
                                    setImage={setCoverImage}
                                    isThumbnailImage={false}
                                    setIsDirty={setIsDirty}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border-2 border-gray-300 rounded-lg mr-5">
                        <GroupItemSelectionView
                            groupItems={groupItems}
                            setGroupItems={setGroupItems}
                            productType={productType}
                            currentPageName={ADD_PRODUCT_GROUP_PAGE}
                        />
                    </div>

                    <div className="w-full py-4 justify-end bg-gray-100 flex mt-3 pr-8">
                        <Buttons
                            name="Cancel"
                            type="button"
                            buttonType="secondary-border-black"
                            id="Cancel"
                            size="e-small"
                            other="mr-3"
                            onclick={() => {
                                history.push('/products/groups');
                            }}
                        />
                        <Buttons
                            name={addGroupLoading ? <NormalLoader /> : 'Save'}
                            type="submit"
                            buttonType="primary"
                            id="update"
                            size="e-small"
                            onclick={() => {
                                setIsDirty(false);
                                if (!addGroupLoading && !error) {
                                    handleSubmit();
                                }
                            }}
                        />
                    </div>
                </>
            )}
        </Formik>
    );
}
