import { useMemo, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import SearchBar from '../../ui/molecules/SearchBar';
import EditableCell from '../../ui/molecules/EditableCell';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';
import {
    CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE,
    CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE,
} from '../../../constants/currency';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import {
    FETCH_GLOBAL_CURRENCIES,
    UPDATE_GLOBAL_CURRENCY_BY_ID,
} from '../../../queries/GlobalCurrencyQueries';
import Loader from '../../../utils/loader';
import { currencyAndCountryCodes } from '../../../utils/currencies';

export default function GlobalCurrencyConversions() {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [conversionId, setConversionId] = useState<number>(null);
    const [conversionRate, setConversionRate] = useState<number>(null);
    const [pageSize, setPageSize] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [globalCurrencyData, setGlobalCurrencyData] = useState([]);
    const [initialGlobalCurrencyData, setInitialGlobalCurrencyData] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Source currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion rate',
                accessor: 'conversionRate',
                Cell: function editConversionRate({ row, value }) {
                    return (
                        <EditableCell
                            id={`${row?.original?.sourceCurrency}-to-${row?.original?.targetCurrency}-convertion-rate`}
                            initialValue={value}
                            row={row}
                            updateValue={updateConversionRate}
                            key={row?.original?.id}
                            isDisabled={
                                row?.original?.sourceCurrency == row?.original?.targetCurrency
                            }
                        />
                    );
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
        ],
        [],
    );

    const [
        loadGlobalCurrencies,
        { data: globalCurrenciesQueryData, loading: globalCurrenciesDataLoading },
    ] = useLazyQuery(FETCH_GLOBAL_CURRENCIES, {
        context: { clientName: ClientsEnum.UTIL },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => {
            setGlobalCurrencyData(globalCurrenciesQueryData.globalCurrencyConversions);
            setInitialGlobalCurrencyData(globalCurrenciesQueryData.globalCurrencyConversions);
            setPageSize(globalCurrenciesQueryData.globalCurrencyConversions.length);
        },
    });

    const [updateGlobalCurrencyById, { loading: updateGlobalCurrencyLoading }] = useMutation(
        UPDATE_GLOBAL_CURRENCY_BY_ID,
        {
            refetchQueries: [FETCH_GLOBAL_CURRENCIES],
            context: { clientName: ClientsEnum.UTIL },
            onCompleted() {
                setShowConfirmationModal(false);
            },
        },
    );

    const sourceCurrencies = [
        ...new Set(
            globalCurrencyData?.map((currency) => currency?.sourceCurrency?.concat(' - Source')),
        ),
    ];
    const targetCurrencies = [
        ...new Set(
            globalCurrencyData?.map((currency) => currency?.targetCurrency?.concat(' - Target')),
        ),
    ];

    const searchSuggestions = sourceCurrencies.concat(targetCurrencies);

    const handleSearchInput = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            console.log(newVal);
            const searchText = newVal
                .toString()
                .replace(' - Source', '')
                .replace(' - Target', '')
                .trim();
            setSearchTerm(searchText);
            let filteredData = null;

            if (newVal.length == 0) {
                filteredData = initialGlobalCurrencyData;
                setGlobalCurrencyData(filteredData);
                setPageSize(filteredData.length);
            } else {
                if (newVal.toString().includes(' - Source')) {
                    filteredData = initialGlobalCurrencyData?.filter((globalCurrency) =>
                        globalCurrency.sourceCurrency.includes(searchText.toUpperCase()),
                    );
                    setGlobalCurrencyData(filteredData);
                    setPageSize(filteredData.length);
                } else if (newVal.toString().includes(' - Target')) {
                    filteredData = initialGlobalCurrencyData?.filter((globalCurrency) =>
                        globalCurrency.targetCurrency.includes(searchText.toUpperCase()),
                    );
                    setGlobalCurrencyData(filteredData);
                    setPageSize(filteredData.length);
                } else {
                    filteredData = initialGlobalCurrencyData?.filter(
                        (globalCurrency) =>
                            globalCurrency.sourceCurrency.includes(searchText.toUpperCase()) ||
                            globalCurrency.targetCurrency.includes(searchText.toUpperCase()),
                    );
                    setGlobalCurrencyData(filteredData);
                    setPageSize(filteredData.length);
                }
            }
        }
    };

    const updateConversionRate = (id, value) => {
        setConversionId(id);
        setConversionRate(value);
        setShowConfirmationModal(true);
    };

    const handleUpdateCurrencyConversionRate = () => {
        updateGlobalCurrencyById({
            variables: {
                globalCurrencyConversionInput: { id: conversionId, conversionRate: conversionRate },
            },
        });
    };

    useEffect(() => {
        loadGlobalCurrencies();
    }, [loadGlobalCurrencies]);

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <ConfirmationModalWithMessage
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                message={CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_MESSAGE}
                messageTitle={CONFIRMATION_UPDATE_GLOBAL_CONVERSION_RATE_TITLE}
                setYesBtnClick={() => handleUpdateCurrencyConversionRate()}
                setNoBtnClick={() => {
                    setShowConfirmationModal(false);
                }}
                isProcessing={updateGlobalCurrencyLoading}
            />
            <div className="flex p-4">
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                        <SearchBar
                            id="supplier-list-search-field"
                            options={searchSuggestions}
                            handleChange={(e, newVal, reason) => {
                                setSearchClicked(true);
                                handleSearchInput(e, newVal, reason);
                            }}
                            placeHolder={'Search by currency'}
                        />
                    </div>
                </div>
            </div>
            {globalCurrenciesDataLoading ? (
                <div className="w-full">
                    <div className="m-auto flex flex-col ">
                        <div className="m-auto p-10">
                            <Loader />
                        </div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    <>
                        {globalCurrencyData?.length && initialGlobalCurrencyData?.length ? (
                            <DataTableAlter
                                columns={columns}
                                data={globalCurrencyData}
                                hidePagination={true}
                                customizedPageSize={initialGlobalCurrencyData.length}
                                currentPage={currentPage}
                                pageSizes={pageSize}
                                setCurrentPage={setCurrentPage}
                                setDefaultPageSize={setPageSize}
                            />
                        ) : searchClicked && !globalCurrencyData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10 flex">
                                    Your search
                                    <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                    did not match any results
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </>
                </div>
            )}
        </div>
    );
}
