import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import DataTable from '../../../ui/organisms/DataTable';
import { FETCH_PARTNER_DETAILS_FOR_DEALS } from '../../../../queries/PartnerQueries';
import SearchBar from '../../../ui/molecules/SearchBar';
import Loader from '../../../../utils/loader';
import TabViewWithCounts from '../../../ui/organisms/TabViewWithCounts';
import { Buttons } from '../../../ui/atoms/Button';
import { useHistory } from 'react-router-dom';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import { useDealForm } from '../../../../contexts/DealFormContext';

export const SelectPartnerView = () => {
    const history = useHistory();
    const { formData, updateSelectedPartners } = useDealForm();
    const [partners, setPartners] = useState([]);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedTab, setSelectedTab] = useState(1);
    const [tabItemsList, setTabItemsList] = useState([
        { text: 'All', count: 0, tabId: 1 },
        { text: 'Selected', count: 0, tabId: 2 },
    ]);
    const [partnerIds, setPartnerIds] = useState([]);
    const [partnerNames, setPartnerNames] = useState([]);
    const [showWarningModal, setShowWarningModal] = useState(false);

    // Fetch partner data
    const {
        data,
        loading,
        error: queryError,
    } = useQuery(FETCH_PARTNER_DETAILS_FOR_DEALS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const activePartners = data?.partners?.filter((partner) => partner?.isActive);
            setPartners(activePartners || []);
            setFilteredPartners(activePartners || []);
            setIsLoading(false);
            setTabItemsList((prevTabs) => [
                { ...prevTabs[0], count: activePartners?.length || 0 },
                { ...prevTabs[1], count: formData.selectedPartners.size || 0 },
            ]);
        },
        onError: (error) => {
            setError(error.message);
            setIsLoading(false);
        },
    });

    useEffect(() => {
        const ids = partners.map((partner) => partner.partnerId);
        const names = partners.map((partner) => partner.partnerName);

        setPartnerIds(ids);
        setPartnerNames(names);
    }, [partners]);

    const partnerDataArray = [...partnerNames, ...partnerIds];

    // Search handling
    useEffect(() => {
        if (searchTerm) {
            const filtered = partners?.filter(
                (p) =>
                    p.partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    p.partnerId.includes(searchTerm),
            );
            setFilteredPartners(filtered);
        } else {
            setFilteredPartners(partners);
        }
    }, [searchTerm, partners]);

    // Update tab counts
    useEffect(() => {
        setTabItemsList((prevTabs) => [
            { ...prevTabs[0], count: filteredPartners?.length || 0 },
            { ...prevTabs[1], count: formData.selectedPartners.size || 0 },
        ]);
    }, [filteredPartners, formData.selectedPartners]);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const handleSelectPartner = (id) => {
        updateSelectedPartners(id);
    };

    const displayedPartners = useMemo(() => {
        if (selectedTab === 1) {
            return filteredPartners;
        }
        return filteredPartners.filter((partner) => formData.selectedPartners.has(partner.id));
    }, [selectedTab, filteredPartners, formData.selectedPartners]);

    const columns = useMemo(
        () => [
            {
                Header: 'Select',
                accessor: 'select',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        checked={formData.selectedPartners.has(row.original.id)}
                        onChange={() => handleSelectPartner(row.original.id)}
                    />
                ),
            },
            { Header: 'Partner ID', accessor: 'partnerId' },
            { Header: 'Partner Name', accessor: 'partnerName' },
            {
                Header: 'Country/Region',
                accessor: 'users',
                Cell: ({ value }) => {
                    const country = value[0]?.contact?.countryCode || 'N/A';
                    return <span>{country}</span>;
                },
            },
            { Header: 'Local Currency', accessor: 'localCurrency' },
            { Header: 'URL', accessor: 'partnerUrl' },
        ],
        [formData.selectedPartners],
    );

    const handleContinueClick = () => {
        if (formData.selectedPartners.size === 0) {
            setShowWarningModal(true);
        } else {
            history.push('/deals/select-payment-channels');
        }
    };

    return (
        <div className="h-full flex flex-col  justify-evens">
            <div className="text-lg font-poppins font-bold ml-3 px-10">Partners</div>
            <div className="border-2 border-gray-300 rounded-lg pt-3 mt-6 mb-24 mx-10">
                <div className="pl-6">
                    <TabViewWithCounts
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabItemsList={tabItemsList}
                    />
                </div>
                <div>
                    <div className="relative flex-grow-0 w-full md:w-1/2 lg:w-1/3 mb-4 p-6">
                        <SearchBar
                            handleChange={(e, newVal, reason) => {
                                handleChange(e, newVal, reason);
                            }}
                            placeHolder="Search by name or ID"
                            options={partnerDataArray}
                            id="select-partner-search-field"
                        />
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : error ? (
                        <div className="error">{error}</div>
                    ) : (
                        <DataTable columns={columns} data={displayedPartners} />
                    )}
                </div>
            </div>
            <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                <Buttons
                    name="Go Back"
                    type="button"
                    buttonType="secondary-border-black"
                    id="cancel-button"
                    size="e-small"
                    other="mr-3"
                    onclick={() => {
                        history.push('/deals/general-details-add');
                    }}
                />
                <Buttons
                    name={'Continue'}
                    type="submit"
                    buttonType="primary"
                    id="save-button"
                    size="e-small"
                    onclick={handleContinueClick}
                />
            </div>
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={'Please select at least one partner.'}
                warningModalTitle={'Error'}
            />
        </div>
    );
};
