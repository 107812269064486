import { useEffect, useState } from 'react';
import { Collapse, createTheme, IconButton, MuiThemeProvider, Tooltip } from '@material-ui/core';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import { RoundOffBoundary } from '../../../../types/deals';
import { PaymentMethod } from '../../../../enums/payment';
import ViewDealPrices from '../ViewDealPrices';
import { useDealForm } from '../../../../contexts/DealFormContext';

const CATALOG_PREFIX = 'catalog--';
const CATEGORY_PREFIX = 'category--';
const GROUP_PREFIX = 'group--';
const ITEM_PREFIX = 'item--';

const theme = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                color: 'white',
                backgroundColor: 'gray',
            },
            arrow: {
                color: 'gray',
            },
        },
    },
});

const TreeNode = ({
    node,
    onSelectToggle,
    selectedContent,
    expandedNodes,
    setExpandedNodes,
    findInTree,
}) => {
    const [expanded, setExpanded] = useState(expandedNodes.includes(node.id));
    const [showToolTip, setShowToolTip] = useState(false);
    const [isViewPricesModalVisible, setIsViewPricesModalVisible] = useState(false);
    const [dealPaymentMethods, setDealPaymentMethods] = useState(null);
    const [dealPercenatgeDeatils, setDealPercenatgeDeatils] = useState(null);
    const [itemPrimaryId, setItemPrimaryId] = useState(null);
    const [partnerId, setPartnerId] = useState(null);
    const [itemSourceCurrency, setItemSourceCurrency] = useState(null);
    const [itemUnitPrice, setItemUnitPrice] = useState(null);
    const { formData, updateFormData } = useDealForm();
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(
        formData.selectedPaymentMethods,
    );

    const handleToggleExpand = () => {
        const newExpandedState = !expanded;
        setExpanded(newExpandedState);
        setExpandedNodes((prev) =>
            newExpandedState ? [...prev, node.id] : prev.filter((id) => id !== node.id),
        );
    };

    const viewDealPriceModal = () => {
        let dcbSelected = false;
        let pointsSelected = false;
        let pspSelected = false;

        if (selectedPaymentMethods.includes(PaymentMethod.DCB)) {
            dcbSelected = true;
        }

        if (selectedPaymentMethods.includes(PaymentMethod.POINTS)) {
            pointsSelected = true;
        }

        if (selectedPaymentMethods.includes(PaymentMethod.PSP)) {
            pspSelected = true;
        }

        const dealPaymentMethods = {
            isDcbSelected: dcbSelected,
            isPointsSelected: pointsSelected,
            isPspSelected: pspSelected,
        };

        const dealPercentageDetails = {
            dealPercentage: formData.percentage,
            finalPriceRoundOffBoundary: formData.roundBoundary,
            finalPriceRoundOffDecimalPlace: formData.decimalPlaces,
        };

        setPartnerId(node.partnerId);
        setItemPrimaryId(node.itemPrimaryId);
        setItemSourceCurrency(node.sourceCurrency);
        setItemUnitPrice(node.unitPrice);
        setDealPaymentMethods(dealPaymentMethods);
        setDealPercenatgeDeatils(dealPercentageDetails);
        setIsViewPricesModalVisible(true);
    };

    const handleSelect = (event) => {
        const checked = event.target.checked;
        onSelectToggle(event, node.id);

        if (node.children) {
            node.children.forEach((child) => onSelectToggle({ target: { checked } }, child.id));
        }
    };

    const areAllChildrenSelected = () => {
        if (!node.children || node.children.length === 0) return false;
        return node.children
            .filter(
                (child) =>
                    !(
                        child.id.startsWith(GROUP_PREFIX) &&
                        node?.children?.length == 1 &&
                        node?.children[0]?.id?.startsWith(`${ITEM_PREFIX}no--items`)
                    ),
            )
            .every((child) => selectedContent.includes(child.id));
    };

    const getNodeFirstLayerStyles = (nodeId) => {
        if (nodeId.startsWith(`${ITEM_PREFIX}no--items`)) {
            return 'bg-purple-100';
        } else if (nodeId.startsWith(ITEM_PREFIX)) {
            return 'bg-white';
        } else if (nodeId.startsWith(GROUP_PREFIX)) {
            return 'bg-gray-100';
        } else if (nodeId.startsWith(CATEGORY_PREFIX)) {
            return 'bg-gray-300';
        } else {
            return 'bg-purple-300 rounded-md border border-gray-300 overflow-hidden';
        }
    };

    const getNodeSecondLayerStyles = (nodeId) => {
        if (nodeId.startsWith(`${ITEM_PREFIX}no--items`)) {
            return 'ml-21';
        } else if (nodeId.startsWith(ITEM_PREFIX)) {
            return 'ml-24';
        } else if (nodeId.startsWith(GROUP_PREFIX)) {
            return 'ml-16';
        } else if (nodeId.startsWith(CATEGORY_PREFIX)) {
            return 'ml-10';
        } else {
            return '';
        }
    };

    useEffect(() => {
        if (node.children && node.children.some((child) => selectedContent.includes(child.id))) {
            setExpanded(true);
            if (!expandedNodes.includes(node.id)) {
                setExpandedNodes([...expandedNodes, node.id]);
            }
        }
    }, [selectedContent]);

    return (
        <div className={`flex flex-col w-full ${getNodeFirstLayerStyles(node.id)}`}>
            <div
                className={`flex items-center justify-between p-4 ${getNodeSecondLayerStyles(
                    node.id,
                )}`}
            >
                <div className="w-full flex items-center">
                    <input
                        type="checkbox"
                        className={`${
                            (node.id.startsWith(`${ITEM_PREFIX}no--items`) ||
                                (node.id.startsWith(GROUP_PREFIX) &&
                                    node?.children?.length == 1 &&
                                    node?.children[0]?.id?.startsWith(`${ITEM_PREFIX}no--items`)) ||
                                (node?.itemId && node?.isAlreadyInDeal === true)) &&
                            'hidden'
                        } -mt-3`}
                        checked={selectedContent.includes(node.id) || areAllChildrenSelected()}
                        onChange={handleSelect}
                    />
                    {!node.id.startsWith(`${ITEM_PREFIX}no--items`) &&
                    node.id.startsWith(ITEM_PREFIX) ? (
                        <>
                            {node.isAlreadyInDeal ? (
                                <div className="w-full flex justify-between ml-5 text-gray-500">
                                    <div className="w-4/12">{node.itemId}</div>
                                    <div className="w-5/12 truncate text-left">{node.name}</div>
                                    <div className="text-purple-500 underline cursor-pointer">
                                        <div
                                            className="w-full order-2 py-0.5 justify-end  flex"
                                            onMouseOver={() => setShowToolTip(true)}
                                            onFocus={() => setShowToolTip(true)}
                                            onMouseLeave={() => setShowToolTip(false)}
                                        >
                                            <img
                                                className={`w-5 h-6 ${
                                                    showToolTip ? 'hidden' : 'block'
                                                }`}
                                                src="/images/icons/info.png"
                                                alt="question"
                                                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => setShowToolTip(true)}
                                                onKeyDown={() => setShowToolTip(true)}
                                            />
                                            {showToolTip ? (
                                                <MuiThemeProvider theme={theme}>
                                                    <Tooltip
                                                        key={1}
                                                        title={
                                                            'This item is already assigned to a deal for this partner for the selected duration.'
                                                        }
                                                        arrow
                                                        placement="right-start"
                                                    >
                                                        <img
                                                            className="w-5 h-6"
                                                            src="/images/icons/info.png"
                                                            alt="question"
                                                        />
                                                    </Tooltip>
                                                </MuiThemeProvider>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full flex justify-between ml-2">
                                    <div className="w-4/12">{node.itemId}</div>
                                    <div className="w-5/12 truncate text-left">{node.name}</div>
                                    <div
                                        className="text-purple-500 underline cursor-pointer"
                                        role="button"
                                        onClick={() => viewDealPriceModal()}
                                        onKeyDown={viewDealPriceModal}
                                        tabIndex={0}
                                    >
                                        View Deal Prices
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <span className="ml-2">{node.name}</span>
                    )}
                </div>
                {node.children && node.children.length > 0 && (
                    <IconButton size="small" onClick={handleToggleExpand}>
                        {expanded ? <BsChevronDown /> : <BsChevronRight />}
                    </IconButton>
                )}
            </div>
            {node.children && (
                <Collapse in={expanded}>
                    <div className="">
                        {node.children.map((childNode) => (
                            <TreeNode
                                key={childNode.id}
                                node={childNode}
                                onSelectToggle={onSelectToggle}
                                selectedContent={selectedContent}
                                expandedNodes={expandedNodes}
                                setExpandedNodes={setExpandedNodes}
                                findInTree={findInTree}
                            />
                        ))}
                    </div>
                </Collapse>
            )}
            <div className="relative w-full">
                <ViewDealPrices
                    openModal={isViewPricesModalVisible}
                    setOpenModal={setIsViewPricesModalVisible}
                    partnerId={partnerId}
                    itemId={itemPrimaryId}
                    costPrice={itemUnitPrice}
                    currency={itemSourceCurrency}
                    dealPaymentMethods={dealPaymentMethods}
                    dealPercentageDetails={dealPercenatgeDeatils}
                />
            </div>
        </div>
    );
};

export default TreeNode;
