import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import TreeNode from './EditTreeNode';
import SearchBar from '../../../ui/molecules/SearchBar';
import Loader from '../../../../utils/loader';
import { Buttons } from '../../../ui/atoms/Button';
import Toast from '../../../ui/atoms/Toast';
import WarningMessageModal from '../../../templates/modals/WarningMessageModal';
import NormalLoader from '../../../../utils/normalLoader';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import {
    FETCH_DEAL_BY_ID,
    FETCH_SELECTED_PARTNERS_BY_DEAL_ID,
    FETCH_SELECTED_PAYMENT_CHANNELS_BY_DEAL_ID,
    GET_DEAL_ITEMS,
    GET_DEAL_SELECTED_ITEMS,
    SAVE_DEAL,
} from '../../../../queries/DealQueries';
import { ERROR_EDIT_DEAL, DEAL_EDIT_SUCCESS } from '../../../../constants/deal';
import { PaymentMethod } from '../../../../enums/payment';
import { DealPaymentChannelsDto } from '../../../../types/deals';
import { useDispatch } from 'react-redux';
import { saveDealId } from '../../../../redux/rootActions';

const CATALOG_PREFIX = 'catalog--';
const CATEGORY_PREFIX = 'category--';
const GROUP_PREFIX = 'group--';
const ITEM_PREFIX = 'item--';

export default function EditDealItems() {
    const dispatch = useDispatch();
    const history = useHistory();
    const parameters = useParams();
    const dealID = parameters['id'];
    const [treeContent, setTreeContent] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [selectedContent, setSelectedContent] = useState([]);
    // states for  deal status

    const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);
    const [selectedPaymentChannels, setSelectedPaymentChannels] = useState([]);
    const [selectedPaymentChannelsIds, setSelectedPaymentChannelsIds] = useState([]);
    const [SELECTED_PAYMENT_METHODS, setSelectedPaymentMethods] = useState([]);
    const [selectedCatalogItems, setselectedCatalogItems] = useState([]);

    // Fetch partner data
    const { data: PartnerData, error: queryError } = useQuery(FETCH_SELECTED_PARTNERS_BY_DEAL_ID, {
        variables: { dealId: dealID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const partnerIds = data?.dealSelectedPartners?.map((partner) => partner?.id);
            setSelectedPartnerIds(partnerIds);
        },
    });

    //fetch paymenth channels and methods
    const { data: PaymentChannelsData } = useQuery(FETCH_SELECTED_PAYMENT_CHANNELS_BY_DEAL_ID, {
        variables: { dealId: dealID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            if (PaymentChannelsData != null) {
                setSelectedPaymentChannels(
                    PaymentChannelsData?.dealSelectedPaymentChannels?.paymentChannels,
                );
                const paymentMethods = getSelectedPaymentMethods(
                    PaymentChannelsData?.dealSelectedPaymentChannels,
                );
                setSelectedPaymentMethods(paymentMethods);
                const payemntChannelIds = selectedPaymentChannels.map(
                    (paymentChannel) => paymentChannel.id,
                );
                setSelectedPaymentChannelsIds(payemntChannelIds);
            }
        },
    });

    //fetch deal general data
    const { data: dealData } = useQuery(FETCH_DEAL_BY_ID, {
        variables: { dealId: dealID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setError(false);
        },
        onError(error: any) {
            const graphQLErrors = error.graphQLErrors;
            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1003) {
                    setMessage(graphQLErrors[0].message);
                    setShowToast(true);
                    setError(true);
                }
            } else {
                setMessage('Error');
                setShowToast(true);
                setError(true);
            }
        },
    });

    // Query to fetch existing deal items
    const { data: selectedItemsData, loading: loadingSelectedItems } = useQuery(
        GET_DEAL_SELECTED_ITEMS,
        {
            variables: { dealId: dealID },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                if (data && data.dealSelectedPartnerItems) {
                    const selectedItems = data.dealSelectedPartnerItems.map(
                        (item) => `item--${item.catalogId}--${item.itemId}`,
                    );
                    setSelectedContent(selectedItems);
                    const selectedCatalogItemData = data.dealSelectedPartnerItems.map((item) => ({
                        catalogId: item.catalogId,
                        itemId: item.itemId,
                    }));
                    setselectedCatalogItems(selectedCatalogItemData);
                }
            },
        },
    );

    // Query to fetch all deal items for selection
    const { data: productGroupData, loading } = useQuery(GET_DEAL_ITEMS, {
        variables: {
            partnerIds: selectedPartnerIds,
            paymentChannelIds: selectedPaymentChannelsIds,
            paymentMethods: SELECTED_PAYMENT_METHODS,
            searchText: searchTerm,
            dealDateInput: {
                startDateTime: dealData?.deal?.startDateTime,
                endDateTime: dealData?.deal?.endDateTime,
            },
        },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            updateNodeData(data);
        },
    });

    const [saveDeal, { loading: saveDealLoading }] = useMutation(SAVE_DEAL, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_DEAL_BY_ID],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setError(false);
            setShowToast(true);
            setMessage(DEAL_EDIT_SUCCESS);
            setTimeout(() => {
                history.push('/deals');
            }, 2000);
        },
        onError: () => {
            setMessage(ERROR_EDIT_DEAL);
            setShowToast(true);
            setError(true);
        },
    });

    useEffect(() => {
        dispatch(saveDealId(dealID));
    }, []);

    const getSelectedPaymentMethods = (paymentMethodDetails: DealPaymentChannelsDto): any => {
        const paymentMethodsArray = [];

        if (paymentMethodDetails != null) {
            if (paymentMethodDetails?.isDcbSelected) {
                paymentMethodsArray.push(PaymentMethod.DCB);
            }

            if (paymentMethodDetails?.isPspSelected) {
                paymentMethodsArray.push(PaymentMethod.PSP);
            }

            if (paymentMethodDetails?.isPointsSelected) {
                paymentMethodsArray.push(PaymentMethod.POINTS);
            }
        }
        return paymentMethodsArray;
    };

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const updateNodeData = (nodeData) => {
        // Update the node data
        const updatedNodeData = nodeData.dealItemsByPartnerIdsAndPaymentChannelIds.map(
            (partner) => {
                const updatedCategories = partner.categories.map((category) => {
                    const updatedProductGroups = category.productGroups.map((group) => {
                        const updatedItems = group.items.map((item) => {
                            // Check if the current item exists in the selected items list
                            const isItemSelected = selectedCatalogItems.some(
                                (selectedItem) =>
                                    selectedItem.catalogId === partner.catalogId.toString() &&
                                    selectedItem.itemId === item.id,
                            );

                            // Return the updated item object with the modified isAlreadyInDeal property
                            return {
                                ...item,
                                isAlreadyInDeal: isItemSelected ? false : item.isAlreadyInDeal,
                            };
                        });

                        return {
                            ...group,
                            items: updatedItems,
                        };
                    });

                    return {
                        ...category,
                        productGroups: updatedProductGroups,
                    };
                });

                return {
                    ...partner,
                    categories: updatedCategories,
                };
            },
        );
        const formattedData = formatData(updatedNodeData);
        handleInitialCollapse(formattedData);
        setTreeContent(formattedData);
    };

    /**
     * This function formats the data to a structure that we can give to
     * the tree view component.
     */
    function formatData(data) {
        function getFormattedItems(items, catalogId, categoryId, partnerId) {
            if (items.length) {
                return items.map((item) => ({
                    id: `item--${catalogId}--${item.id}`,
                    name: item.itemName,
                    itemId: item.itemId,
                    categoryId: categoryId,
                    isAlreadyInDeal: item.isAlreadyInDeal,
                    sourceCurrency: item.sourceCurrency,
                    unitPrice: item.unitPrice,
                    itemPrimaryId: item.id,
                    partnerId: partnerId,
                }));
            } else {
                const errorMsg =
                    searchTerm?.length > 0
                        ? 'No products found the search'
                        : 'No products available for the selected channels';
                return [
                    {
                        id: `item--no--items`,
                        name: errorMsg,
                    },
                ];
            }
        }

        function getFormattedGroups(groups, catalogId, categoryId, partnerId) {
            return groups.map((group) => {
                return {
                    id: `group--${catalogId}--${group.id}`,
                    name: group.productGroupName,
                    children: getFormattedItems(group.items, catalogId, categoryId, partnerId),
                };
            });
        }

        function getFormattedCategories(categories, catalogId, partnerId) {
            return categories.map((category) => ({
                id: `category--${catalogId}--${category.id}`,
                name: category.categoryName,
                children: getFormattedGroups(
                    category.productGroups,
                    catalogId,
                    category?.id,
                    partnerId,
                ),
            }));
        }
        return data.map((catalog) => {
            return {
                id: `catalog--${catalog.catalogId}`,
                name: catalog.catalogName,
                children: getFormattedCategories(catalog.categories, catalog.catalogId, catalog.id),
            };
        });
    }

    const handleInitialCollapse = (formattedData) => {
        if (formattedData.length > 0) {
            const firstCatalog = formattedData[0];

            const catalogId = firstCatalog.id;

            const categoryIds = [];
            const groupIds = [];
            const itemIds = [];

            firstCatalog.children.forEach((category) => {
                categoryIds.push(category.id);
                category.children.forEach((group) => {
                    groupIds.push(group.id);
                    group.children.forEach((item) => {
                        itemIds.push(item.id);
                    });
                });
            });
            setExpandedNodes([...categoryIds, ...groupIds, ...itemIds, catalogId]);
        }
    };

    /**
     * This function searches through the tree to find a specific item.
     * @param nodeType either 'catalog' or 'category' or 'group'
     * @param nodeId id of the target
     * @returns the target object
     */
    const findInTree = (nodeType, nodeId) => {
        let found = null;

        // Iterate catalogs
        for (let i = 0; i < treeContent.length; i++) {
            const catalog = treeContent[i];
            if (nodeType === 'catalog' && catalog.catalogId === nodeId) {
                found = catalog;
                break;
            }

            // Iterate categories
            for (let j = 0; j < catalog.children.length; j++) {
                const category = catalog.children[j];
                if (nodeType === 'category' && category.id === nodeId) {
                    found = category;
                    break;
                }

                // Iterate groups
                for (let k = 0; k < category.children.length; k++) {
                    const group = category.children[k];
                    if (nodeType === 'group' && group.id === nodeId) {
                        found = group;
                        break;
                    }
                }

                if (found) break;
            }

            if (found) break;
        }

        return found;
    };

    // Returns an array containing the IDs of the group and its children
    const getGroupAndChildrenIds = (groupId) => {
        const selectedGroup = findInTree('group', groupId);
        const groupItemIds = selectedGroup.children.map((child) => child.id);
        return [groupId, ...groupItemIds];
    };

    // Returns an array containing the IDs of the category and its children
    const getCategoryAndChildrenIds = (categoryId) => {
        const selectedCategory = findInTree('category', categoryId);
        const categoryGroupIds = selectedCategory.children.map((child) => child.id);
        let childrenIds = [];
        categoryGroupIds.forEach((groupId) => {
            const groupAndChildrenIds = getGroupAndChildrenIds(groupId);
            childrenIds = childrenIds.concat(groupAndChildrenIds);
        });
        return [categoryId, ...childrenIds];
    };

    // Returns an array containing the IDs of the catalog and its children
    const getCatalogAndChildrenIds = (catalogId) => {
        const selectedCatalog = findInTree('catalog', catalogId);
        const catalogCategoryIds = selectedCatalog.children.map((child) => child.id);
        let childrenIds = [];
        catalogCategoryIds.forEach((categoryId) => {
            const categoryAndChildrenIds = getCategoryAndChildrenIds(categoryId);
            childrenIds = childrenIds.concat(categoryAndChildrenIds);
        });
        return [catalogId, ...childrenIds];
    };

    // Handles when the checkboxes are ticked/unticked
    const handleSelectToggle = (event, nodeId) => {
        setShowWarningModal(false);
        const checked = event.target.checked;

        if (checked) {
            if (nodeId.startsWith(ITEM_PREFIX)) {
                setSelectedContent((current) => [...current, nodeId]);
            } else if (nodeId.startsWith(GROUP_PREFIX)) {
                const groupAndChildrenIds = getGroupAndChildrenIds(nodeId);
                setSelectedContent((current) => [...current, ...groupAndChildrenIds]);
            } else if (nodeId.startsWith(CATEGORY_PREFIX)) {
                const categoryAndChildrenIds = getCategoryAndChildrenIds(nodeId);
                setSelectedContent((current) => [...current, ...categoryAndChildrenIds]);
            } else {
                const catalogAndChildrenIds = getCatalogAndChildrenIds(nodeId);
                setSelectedContent((current) => [...current, ...catalogAndChildrenIds]);
            }
        } else {
            if (nodeId.startsWith(ITEM_PREFIX)) {
                setSelectedContent((current) => current.filter((itemId) => itemId !== nodeId));
            } else if (nodeId.startsWith(GROUP_PREFIX)) {
                const groupAndChildrenIds = getGroupAndChildrenIds(nodeId);
                setSelectedContent((current) =>
                    current.filter((id) => !groupAndChildrenIds.includes(id)),
                );
            } else if (nodeId.startsWith(CATEGORY_PREFIX)) {
                const categoryAndChildrenIds = getCategoryAndChildrenIds(nodeId);
                setSelectedContent((current) =>
                    current.filter((id) => !categoryAndChildrenIds.includes(id)),
                );
            } else {
                const catalogAndChildrenIds = getCatalogAndChildrenIds(nodeId);
                setSelectedContent((current) =>
                    current.filter((id) => !catalogAndChildrenIds.includes(id)),
                );
            }
        }
    };

    const generateSearchSuggestions = (response) => {
        if (response?.length > 0) {
            // Extract all items
            const items = response.flatMap((partner) =>
                partner.categories.flatMap((category) =>
                    category.productGroups.flatMap((group) => group.items),
                ),
            );

            // Extract unique item names and IDs
            const uniqueItemNames = new Set();
            const uniqueItemIds = new Set();

            items.forEach((item) => {
                if (item.itemName) uniqueItemNames.add(item.itemName);
                if (item.itemId) uniqueItemIds.add(item.itemId);
            });

            // Convert sets to arrays
            const productNames = Array.from(uniqueItemNames);
            const productIds = Array.from(uniqueItemIds);

            // Combine item names and IDs into a single array
            const combinedArray = productNames.concat(productIds);

            return combinedArray;
        } else {
            return [];
        }
    };

    const generateDealActivePartnerItems = () => {
        const selectedItems = selectedContent.filter(
            (selectedItem) =>
                selectedItem.startsWith(ITEM_PREFIX) &&
                !selectedItem.startsWith(`${ITEM_PREFIX}no--items`),
        );
        const distinctItems = [...new Map(selectedItems.map((item) => [item, item])).values()];

        const dealActivePartnerItems = distinctItems.map((item) => {
            const itemSplit = item.split('--');

            return { catalogId: itemSplit[1], itemId: itemSplit[2] };
        });

        return dealActivePartnerItems;
    };

    const generateDealPaymentChannel = () => {
        const dcbChannelsIds = selectedPaymentChannels
            .filter(
                (paymentChannel) =>
                    paymentChannel.paymentMethod === PaymentMethod[PaymentMethod.DCB],
            )
            .map((paymentChannel) => paymentChannel.id);

        const pspChannelsIds = selectedPaymentChannels
            .filter(
                (paymentChannel) =>
                    paymentChannel.paymentMethod === PaymentMethod[PaymentMethod.PSP],
            )
            .map((paymentChannel) => paymentChannel.id);

        const dealPaymentChannel = {
            isPspSelected: SELECTED_PAYMENT_METHODS.includes(PaymentMethod[PaymentMethod.PSP]),
            isDcbSelected: SELECTED_PAYMENT_METHODS.includes(PaymentMethod[PaymentMethod.DCB]),
            isPointsSelected: SELECTED_PAYMENT_METHODS.includes(
                PaymentMethod[PaymentMethod.POINTS],
            ),
            pspChannelIds: pspChannelsIds.length > 0 ? pspChannelsIds : null,
            dcbChannelIds: dcbChannelsIds.length > 0 ? dcbChannelsIds : null,
        };

        return dealPaymentChannel;
    };

    const handleSubmit = () => {
        setError(false);
        setShowWarningModal(false);

        const dealActivePartnerItems = generateDealActivePartnerItems();
        const dealPaymentChannel = generateDealPaymentChannel();

        if (dealActivePartnerItems?.length > 0) {
            saveDeal({
                variables: {
                    dealInput: {
                        id: dealID,
                        dealName: dealData?.deal?.dealName,
                        dealPercentage: dealData?.deal?.dealPercentage,
                        finalPriceRoundOffBoundary:
                            dealData?.deal?.roundBoundary != ''
                                ? dealData?.deal?.roundBoundary
                                : null,
                        finalPriceRoundOffDecimalPlace:
                            dealData?.deal?.decimalPlaces != ''
                                ? dealData?.deal?.decimalPlaces
                                : null,
                        startDateTime: dealData?.deal?.startDateTime,
                        endDateTime: dealData?.deal?.endDateTime,
                        selectedPartnerIds: selectedPartnerIds,
                        isActive: true,
                        dealActivePartnerItems: dealActivePartnerItems,
                        dealPaymentChannel: dealPaymentChannel,
                    },
                },
            });
        } else {
            setShowWarningModal(true);
        }
    };

    return (
        <div className="w-full pl-10 pr-5">
            {showToast && (
                <Toast
                    setShowToast={setShowToast}
                    message={message}
                    width="w-8/12"
                    error={error}
                    selfDestruct={true}
                    selfDestructTimer={3000}
                />
            )}
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={'Please select at least one product.'}
                warningModalTitle={'Alert'}
            />
            <div className="text-lg font-poppins font-bold mb-5">Products</div>
            <div className="border-2 border-gray-300 space-y-5 p-2 py-5 rounded-md mb-20">
                <div className="flex w-full">
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500">
                        <SearchBar
                            id="deals-search-tems"
                            options={generateSearchSuggestions(
                                productGroupData?.dealItemsByPartnerIdsAndPaymentChannelIds,
                            )}
                            handleChange={(e, newVal, reason) => {
                                setSearchClicked(true);
                                handleChange(e, newVal, reason);
                            }}
                            placeHolder={'Search by product name or id'}
                        />
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {treeContent?.length ? (
                            <>
                                {treeContent.map((node) => (
                                    <TreeNode
                                        key={node.id}
                                        node={node}
                                        onSelectToggle={handleSelectToggle}
                                        selectedContent={selectedContent}
                                        expandedNodes={expandedNodes}
                                        setExpandedNodes={setExpandedNodes}
                                        findInTree={findInTree}
                                    />
                                ))}
                            </>
                        ) : (
                            <div>There are no items to display</div>
                        )}
                    </>
                )}
                <div className="flex lg:w-3/4 md:w-1/2 sm:w-1/4 py-4 px-4 justify-end bg-gray-100 fixed bottom-0 right-0 z-20">
                    <Buttons
                        name="Cancel"
                        type="button"
                        buttonType="secondary-border-black"
                        id="cancel-button"
                        size="e-small"
                        other="mr-3"
                        onclick={() => history.push('/deals')}
                    />
                    <Buttons
                        name={saveDealLoading ? <NormalLoader /> : 'Save Deal'}
                        type="submit"
                        buttonType="primary"
                        id="save-button"
                        size="e-small"
                        onclick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}
