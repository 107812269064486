/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ADD_PRODUCTS, FETCH_PRODUCTS, ITEM_LIST_UPDATE } from '../../../queries/ItemQueries';
import { BsThreeDots, BsFillFunnelFill } from 'react-icons/bs';
import TableTabView from './TableTabView';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { FETCH_SUPPLIERS_DROPDOWN } from '../../../queries/SupplierQueries';
import { FETCH_CATEGORIES, FETCH_CATEGORIES_FOR_FILTER } from '../../../queries/CategoryQueries';
import Loader from '../../../utils/loader';
import { pagination } from '../../../constants/config/constants';
import { ItemStatus, ItemType } from '../../../enums/item';
import DataTable from '../../ui/organisms/DataTable';
import SearchBar from '../../ui/molecules/SearchBar';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';
import { useHistory } from 'react-router';
import {
    INSTANT_EDIT_OPTION_RESTORE,
    RESTORE_CONFIRMATION_MESSAGE,
    RESTORE_CONFIRMATION_TITLE,
    INSTANT_EDIT_OPTION_DUPLICATE,
    SUCCESS_MESSAGE_DUPLICATE_PRODUCT,
    SUCCESS_MESSAGE_TRASHED_PRODUCT_PREFIX,
    SUCCESS_MESSAGE_TRASHED_PRODUCT_POSTFIX,
    SUCCESS_MESSAGE_RESTORE_PRODUCT,
    BULK_ACTION_SEND_TO_TRASH,
    BULK_ACTION_RESTORE_ALL,
    SUCCESS_MESSAGE_BULK_ACTIONS_TRASHED,
    SUCCESS_MESSAGE_BULK_ACTIONS_RESTORE_ALL,
    BULK_ACTION_MOVE_TO_UNPUBLISHED,
    INSTANT_EDIT_OPTION_VIEW,
    INSTANT_EDIT_OPTION_UNPUBLISH,
    INSTANT_EDIT_OPTION_EDIT,
    SUCCESS_MESSAGE_UNPUBLISHED_PRODUCT,
    SUCCESS_MESSAGE_BULK_ACTIONS_UNPUBLISHED,
    BULK_ACTION_MOVE_TO_PUBLISHED,
    INSTANT_EDIT_OPTION_TRASH,
    INSTANT_EDIT_OPTION_PUBLISH,
    SUCCESS_MESSAGE_PUBLISHED_PRODUCT_INSTANT_EDIT,
    SUCCESS_MESSAGE_BULK_ACTIONS_PUBLISHED,
    BULK_ACTION_VALIDATION_FAILED_PUBLISHED,
    BULK_ACTION_VALIDATION_FAILED_UNPUBLISHED,
    BULK_ACTION_VALIDATION_FAILED_TITLE,
    DUPLICATE_PRODUCT_PREFIX,
    INSTANT_EDIT_PUBLISH_VALIDATION_FAILED,
    IN_STOCK,
    OUT_OF_STOCK,
} from '../../../constants/product';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import NoPartnerModal from '../../templates/modals/NoPartnersModal';
import Toast from '../../ui/atoms/Toast';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
} from '../../../constants/profile';
import { FETCH_PARTNER_DETAILS_FOR_FILTER } from '../../../queries/PartnerQueries';
import { MappedProductStatus } from '../../../enums/product';
import { FETCH_GLOBAL_POINTS_LIST } from '../../../queries/PointsQueries';
import Multiselect from 'multiselect-react-dropdown';
import { SORT_ASC } from '../../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { setItemID } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import { ALLSERV, API, CSV, CSV_VOUCHERS } from '../../../constants/supplier';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import { getSupplierProductCode } from '../../../helpers/ItemHelpers.helpers';

interface Props {
    isCategoryProductsView?: boolean;
    categoryProductsCounts?: {
        all: number;
        selected: number;
    };
    selectedProduct?: any;
    setSelectedProductsList?: any;
    viewMode?: boolean;
}

export default function ListAllProductScreen({
    isCategoryProductsView,
    categoryProductsCounts,
    selectedProduct,
    setSelectedProductsList,
    viewMode,
}: Props) {
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedPartnerId, setSelectedPartnerId] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productData, setProductData] = useState([]);
    const [checkboxVisible, setCheckboVisible] = useState(true);
    const [productInitialData, setProductInitialData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [selectedPartner, setSelectedPartner] = useState('');
    const [toastError, setToastError] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isSelectedCategoryRemoved, setIsSelectedCategoryRemoved] = useState(true);
    const [isSelectedSupplierRemoved, setIsSelectedSuppliersRemoved] = useState(true);
    const [isSelectedPartnerRemoved, setIsSelectedPartnerRemoved] = useState(true);
    const [isSelectedProductRemoved, setIsSelectedProductRemoved] = useState(true);
    const [isSelectedStockRemoved, setIsSelectedStockRemoved] = useState(true);
    const [selectedProductType, setSelectedProductType] = useState('');
    const [selectedStockType, setSelectedStockType] = useState('');
    const [selectedContentType, setSelectedContentType] = useState('');
    const [isSelectedContentRemoved, setIsSelectedContentRemoved] = useState(true);

    const [supplierData, setSupplierData] = useState([]);
    const [partnerData, setPartnerData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [IsDuplicate, setIsDuplicate] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPageIndex, setCurrentPAgeIndex] = useState(0);
    const [productStatus, setProductStatus] = useState({
        all: 0,
        published: 0,
        draft: 0,
        onHold: 0,
        trashed: 0,
    });
    const [completeQuery, setCompleteQuery] = useState(false);
    const [clickedProduct, setClickedProduct] = useState(null);
    const [duplicatedProduct, setDuplicatedProduct] = useState(null);
    const [duplicateMutationCompleted, setDuplicateMutationCompleted] = useState(false);
    const [statusUpdatedProduct, setStatusUpdatedProduct] = useState(null);
    const [updateStatusProductMutationCompleted, setUpdateStatusProductMutationCompleted] =
        useState(false);
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [bulkActionUpdateStatusMutationCompleted, setBulkActionUpdateStatusMutationCompleted] =
        useState(false);
    const [bulkActionStatusUpdateAppliedProducts, setBulkActionStatusUpdateAppliedProducts] =
        useState([]);
    const [applyBulkActionClicked, setApplyBulkActionClicked] = useState(false);
    const [bulkAction, setBulkAction] = useState('');
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [bulkActionsMenuItems, setBulkActionsMenuItems] = useState([]);
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const [confirmationModalYesClicked, setConfirmationModalYesClicked] = useState(false);
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [selectedCategoryName, setSelectedCategoryName] = useState<any>();
    const [selectedSupplierName, setSelectedSupplierName] = useState<any>();
    const [selectedPartnerNames, setSelectedPartnerNames] = useState([]);
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [showReorderTooltip, setShowReorderTooltip] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);
    const [allProducts, setAllProducts] = useState<any>([]);
    const [viewSelectedProducts, setAllViewSelectedProducts] = useState<any>([]);
    const [pointsData, setPointsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPublishedItems, setTotalPublishedItems] = useState(0);
    const [totalUnpublishedItems, setTotalUnpublishedItems] = useState(0);
    const [totalDeletedItems, setTotalDeletedItems] = useState(0);
    const [categoriesData, setCategoriesData] = useState([]);
    const [selectedTabProducts, setSelectedTabProducts] = useState([]);
    const [sortState, setSortState] = useState<string>(SORT_ASC);
    const [sortField, setSortField] = useState<string>('itemName');
    const [isNoPartnerModalOpen, setIsNoPartnerModalOpen] = useState<boolean>(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const itemID = useSelector((state: RootState) => state.itemIDState.itemID);

    const tabStatus =
        selectedTab === 1
            ? ''
            : selectedTab === 2
            ? ItemStatus.PUBLISHED
            : selectedTab === 3
            ? ItemStatus.UNPUBLISHED
            : ItemStatus.DELETED;

    useEffect(() => {
        const selectedProductIds = selectedProducts?.map((product) => product?.id);

        if (selectedProductIds?.length) {
            const finalListOfSelectedProducts = productInitialData?.filter((product) => {
                return selectedProductIds?.includes(product?.id);
            });

            if (finalListOfSelectedProducts?.length) {
                setSelectedTabProducts(finalListOfSelectedProducts);
            }
        }
    }, [selectedProducts, productInitialData, setSelectedTabProducts]);

    const [fetchItemData, { loading, error }] = useLazyQuery(FETCH_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },

        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
            setProductData(data?.items?.items);
            setProductInitialData(data?.items?.items);
            setCompleteQuery(true);
            setTotalItems(data?.items?.totalItems);
            setCurrentPAgeIndex(data?.items?.currentPage);
            setTotalPages(data?.items?.totalPages);
            setTotalPublishedItems(data?.items?.totalPublishedItems);
            setTotalUnpublishedItems(data?.items?.totalUnpublishedItems);
            setTotalDeletedItems(data?.items?.totalDeletedItems);
        },
    });

    const { data: categoryDatas } = useQuery(FETCH_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: IsDuplicate ? 'cache-only' : 'network-only',
        nextFetchPolicy: IsDuplicate ? 'cache-only' : 'network-only',
        onCompleted: () => {
            setCategoriesData(categoryDatas?.categories);
        },
    });

    const { data: supplierQueryData } = useQuery(FETCH_SUPPLIERS_DROPDOWN, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setSupplierData(
                supplierQueryData?.suppliers?.filter((supplierItem) => supplierItem?.isActive),
            );
        },
    });

    const { data: categoryQueryData } = useQuery(FETCH_CATEGORIES_FOR_FILTER, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setCategoryData(categoryQueryData?.categories);
        },
    });

    const [duplicateProduct] = useMutation(ADD_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setDuplicatedProduct(data?.saveItem);
            setDuplicateMutationCompleted(true);
        },
    });

    const { loading: partnerDataLoading, error: partnerDataFetchError } = useQuery(
        FETCH_PARTNER_DETAILS_FOR_FILTER,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                setPartnerData(data?.partners);
            },
        },
    );

    const { loading: pointsLoading, data: pointsDataQuery } = useQuery(FETCH_GLOBAL_POINTS_LIST, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setPointsData(data?.globalPointsList);
        },
    });
    const isRegistered = localStorage.getItem('isRegistered');

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === NOT_REGISTERED) {
            history.push('/adminProfile');
        }
    }, [history, warningModalOkayClicked]);

    const [updateProductStatus] = useMutation(ADD_PRODUCTS, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setConfirmationModalYesClicked(false);
            setSelectedMenuItem('');
            setConfirmationModalYesClicked(false);
            setShowConfirmationModal(false);
            setSelectedMenuItem('');

            setStatusUpdatedProduct(data?.saveItem);
            setUpdateStatusProductMutationCompleted(true);
        },
        onError: (error) => {
            setConfirmationModalYesClicked(false);
            setSelectedMenuItem('');
            setConfirmationModalYesClicked(false);
            setShowConfirmationModal(false);
            setSelectedMenuItem('');

            const graphQLErrors = error.graphQLErrors;

            if (graphQLErrors && graphQLErrors.length > 0) {
                if (graphQLErrors[0].extensions.errorCode === 1645) {
                    setWarningModalTitle('Error');
                    setWarningModalBody(
                        'This item cannot be inactivated/trashed as it is currently assigned to an active deal.',
                    );
                    setShowWarningModal(true);
                }
            } else {
                setShowToast(true);
                setToastError(true);
                setMessage(error?.message);
                setTimeout(() => {
                    setToastError(false);
                    setShowToast(false);
                }, 4000);
            }
        },
    });

    const [bulkActionUpdateStatus] = useMutation(ITEM_LIST_UPDATE, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setBulkActionStatusUpdateAppliedProducts(data?.updateItemList);
            setBulkActionUpdateStatusMutationCompleted(true);
            setApplyBulkActionClicked(false);
            setTimeout(() => {
                setBulkActionUpdateStatusMutationCompleted(false);
            }, 2000);
        },
        onError: (e) => {
            setToastError(true);
            setMessage(e.message);
            setShowToast(true);
            setTimeout(() => {
                setToastError(false);
                setShowToast(false);
            }, 4000);
        },
    });

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedTab,
        pageSize,
        selectedProductType,
        selectedSupplier,
        selectedCategory,
        selectedPartnerId,
    ]);

    useEffect(() => {
        fetchItemData({
            variables: {
                filter: {
                    itemStatus:
                        selectedTab === 2
                            ? ItemStatus.PUBLISHED
                            : selectedTab === 3
                            ? ItemStatus.UNPUBLISHED
                            : selectedTab === 5
                            ? ItemStatus.DELETED
                            : null,

                    itemType: isFilterClicked && selectedProductType ? selectedProductType : null,
                    suppliers: isFilterClicked && selectedSupplier ? [selectedSupplier] : null,
                    partners:
                        isFilterClicked && selectedPartnerId?.length ? selectedPartnerId : null,
                    categories: isFilterClicked && selectedCategory ? [selectedCategory] : null,
                    inStock: isFilterClicked && selectedStockType ? selectedStockType : null,
                    contentType:
                        isFilterClicked && selectedContentType ? selectedContentType : null,
                },
                offset: currentPage - 1 || 0,
                limit: Math.ceil(pageSize) || 10000,
                sort: sortField,
                order: sortState,
                searchText: searchTerm && searchClicked ? searchTerm : null,
            },
        });
    }, [
        isFilterClicked,
        selectedProductType,
        selectedSupplier,
        selectedCategory,
        selectedStockType,
        selectedPartnerId,
        totalItems,
        currentPage,
        pageSize,
        selectedTab,
        searchTerm,
        searchClicked,
        bulkActionUpdateStatusMutationCompleted,
        sortField,
        sortState,
        selectedContentType,
    ]);

    const categoriesDropDownList = categoryData?.map((category) => ({
        text: category.categoryName,
        value: category.id,
    }));

    const suppliersDropDownList = supplierData?.map((supplier) => ({
        text: supplier.supplierName,
        value: supplier.id,
    }));

    const partnerDropDownList = partnerData?.map((partner) => ({
        text: partner.partnerName,
        value: partner.id,
    }));

    const productTypeOptions = [
        {
            text: 'Physical',
            value: 'PHYSICAL',
        },
        {
            text: 'Virtual',
            value: 'VIRTUAL',
        },
    ];

    const stockTypeOptions = [
        {
            text: 'In Stock',
            value: true,
        },
        {
            text: 'Out of stock',
            value: false,
        },
    ];

    const contentTypeOptions = [
        {
            text: 'NORMAL',
            value: 'NORMAL',
        },
        {
            text: 'PREMIUM',
            value: 'PREMIUM',
        },
    ];

    useEffect(() => {
        const productCounts = {
            all:
                Math.ceil(totalPublishedItems + totalUnpublishedItems) <= 0
                    ? 0
                    : Math.ceil(totalPublishedItems + totalUnpublishedItems),
            published: Math.ceil(totalPublishedItems),
            draft: Math.ceil(totalUnpublishedItems),
            onHold: Math.ceil(totalUnpublishedItems),
            trashed: Math.ceil(totalDeletedItems),
        };

        setProductStatus(productCounts);
    }, [
        completeQuery,
        bulkActionUpdateStatusMutationCompleted,
        totalPublishedItems,
        totalUnpublishedItems,
        totalDeletedItems,
    ]);

    useEffect(() => {
        if (selectedProducts?.length && isCategoryProductsView) {
            setSelectedProductsList(selectedProducts);
        }
    }, [isCategoryProductsView, selectedProducts, setSelectedProductsList]);

    useEffect(() => {
        if (!isFilterClicked) {
            setSelectedCategory(null);
            setSelectedSupplier(null);
            setSelectedPartner(null);
            setSelectedProductType('');
        }
    }, [isFilterClicked]);

    useEffect(() => {
        if (selectedProducts?.length > 1) {
            setShowBulkActions(true);
        } else {
            setShowBulkActions(false);
        }
    }, [selectedProducts]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_VIEW && clickedProduct) {
            setSelectedMenuItem('');
            history.push(`/products/general-view/${clickedProduct?.id}`);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_EDIT && clickedProduct) {
            setSelectedMenuItem('');
            history.push(`/products/general-edit/${clickedProduct?.id}`);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_UNPUBLISH && clickedProduct) {
            handleUpdateProductStatus(clickedProduct, ItemStatus.UNPUBLISHED);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_PUBLISH && clickedProduct) {
            if (validatePublish(clickedProduct)) {
                handleUpdateProductStatus(clickedProduct, ItemStatus.PUBLISHED);
            } else {
                setWarningModalBody(INSTANT_EDIT_PUBLISH_VALIDATION_FAILED);
                setWarningModalTitle(BULK_ACTION_VALIDATION_FAILED_TITLE);
                setShowWarningModal(true);
            }
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_TRASH && clickedProduct) {
            handleUpdateProductStatus(clickedProduct, ItemStatus.DELETED);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE && clickedProduct) {
            setConfirmationMessage(RESTORE_CONFIRMATION_MESSAGE);
            setConfirmationMessageTitle(RESTORE_CONFIRMATION_TITLE);
            setShowConfirmationModal(true);
        } else if (selectedMenuItem === INSTANT_EDIT_OPTION_DUPLICATE && clickedProduct) {
            setIsDuplicate(true);
            handleDuplicateProduct(clickedProduct);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenuItem, clickedProduct]);

    useEffect(() => {
        if (!showConfirmationModal) {
            setSelectedMenuItem('');
        }
    }, [showConfirmationModal]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    useEffect(() => {
        if (confirmationModalYesClicked) {
            setShowConfirmationModal(false);
            if (selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE) {
                handleUpdateProductStatus(clickedProduct, ItemStatus.UNPUBLISHED);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationModalYesClicked]);

    useEffect(() => {
        if (duplicateMutationCompleted && duplicatedProduct !== undefined) {
            const index = productInitialData.findIndex((i) => i.itemId === clickedProduct?.itemId);
            const newList = productInitialData.slice(0);
            duplicatedProduct?.categories?.map((category) => {
                clickedProduct?.categories?.map((clickedCat) => {
                    category.categoryName = clickedCat?.categoryName;
                });
            });

            newList.splice(index + 1, 0, duplicatedProduct);
            setProductInitialData(newList);
            setMessage(SUCCESS_MESSAGE_DUPLICATE_PRODUCT + clickedProduct?.itemName);
            setShowToast(true);
            setShowReorderTooltip(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duplicateMutationCompleted, duplicatedProduct]);

    useEffect(() => {
        if (updateStatusProductMutationCompleted && statusUpdatedProduct !== null) {
            const index = productInitialData.findIndex((i) => i.itemId === clickedProduct?.itemId);
            const newList = productInitialData.slice(0);
            statusUpdatedProduct.categories = clickedProduct?.categories?.map((clickedCat) => {
                return {
                    __typename: 'Category',
                    id: clickedCat?.id,
                    categoryId: clickedCat?.categoryId,
                    categoryName: clickedCat?.categoryName,
                };
            });

            newList[index] = statusUpdatedProduct;
            setProductInitialData(newList);
            if (statusUpdatedProduct?.itemStatus === ItemStatus.DELETED) {
                setMessage(
                    SUCCESS_MESSAGE_TRASHED_PRODUCT_PREFIX +
                        statusUpdatedProduct?.itemName +
                        SUCCESS_MESSAGE_TRASHED_PRODUCT_POSTFIX,
                );
            }

            if (statusUpdatedProduct?.itemStatus === ItemStatus.UNPUBLISHED) {
                setMessage(SUCCESS_MESSAGE_RESTORE_PRODUCT);
            }

            if (
                statusUpdatedProduct?.itemStatus === ItemStatus.UNPUBLISHED &&
                clickedProduct?.itemStatus === ItemStatus.PUBLISHED
            ) {
                setMessage(SUCCESS_MESSAGE_UNPUBLISHED_PRODUCT + statusUpdatedProduct?.itemName);
            }

            if (
                statusUpdatedProduct?.itemStatus === ItemStatus.PUBLISHED &&
                clickedProduct?.itemStatus === ItemStatus.UNPUBLISHED
            ) {
                setMessage(
                    SUCCESS_MESSAGE_PUBLISHED_PRODUCT_INSTANT_EDIT + statusUpdatedProduct?.itemName,
                );
            }

            setShowToast(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatusProductMutationCompleted, statusUpdatedProduct]);

    useEffect(() => {
        const selectedProductsDetails = productInitialData?.filter((initProd) =>
            selectedProducts?.some((prod) => prod === initProd?.id),
        );
        if (
            bulkActionsMenuItems.some(
                (action) => action.value === bulkAction && applyBulkActionClicked,
            )
        ) {
            const productList = selectedProductsDetails?.slice(0);
            const idList = productList?.map((item) => item.id);
            if (
                bulkAction === BULK_ACTION_SEND_TO_TRASH &&
                selectedProducts.length > 1 &&
                applyBulkActionClicked
            ) {
                setShowToast(true);
                setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_TRASHED);
                handleBulkAction(idList, ItemStatus.DELETED);
            }
            if (
                bulkAction === BULK_ACTION_RESTORE_ALL &&
                selectedProducts.length > 1 &&
                applyBulkActionClicked
            ) {
                handleBulkAction(idList, ItemStatus.UNPUBLISHED);
            }

            if (
                bulkAction === BULK_ACTION_MOVE_TO_UNPUBLISHED &&
                selectedProducts.length > 1 &&
                applyBulkActionClicked
            ) {
                if (
                    validateBulkActions(BULK_ACTION_MOVE_TO_UNPUBLISHED, selectedProductsDetails) ||
                    selectedProductsDetails?.some(
                        (item) => item.itemStatus !== ItemStatus.UNPUBLISHED,
                    )
                ) {
                    setShowToast(true);
                    setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_UNPUBLISHED);
                    handleBulkAction(idList, ItemStatus.UNPUBLISHED);
                } else {
                    setWarningModalBody(BULK_ACTION_VALIDATION_FAILED_UNPUBLISHED);
                    setWarningModalTitle(BULK_ACTION_VALIDATION_FAILED_TITLE);
                    setShowWarningModal(true);
                }
            }

            if (
                bulkAction === BULK_ACTION_MOVE_TO_PUBLISHED &&
                selectedProducts.length > 1 &&
                applyBulkActionClicked
            ) {
                if (
                    validateBulkActions(BULK_ACTION_MOVE_TO_PUBLISHED, selectedProductsDetails) ||
                    selectedProductsDetails?.some(
                        (item) => item.itemStatus !== ItemStatus.PUBLISHED,
                    )
                ) {
                    setShowToast(true);
                    setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_PUBLISHED);
                    handleBulkAction(idList, ItemStatus.PUBLISHED);
                } else {
                    setWarningModalBody(BULK_ACTION_VALIDATION_FAILED_PUBLISHED);
                    setWarningModalTitle(BULK_ACTION_VALIDATION_FAILED_TITLE);
                    setShowWarningModal(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        applyBulkActionClicked,
        selectedProducts,
        productInitialData,
        bulkAction,
        selectedProducts.length,
    ]);

    useEffect(() => {
        if (
            bulkActionUpdateStatusMutationCompleted &&
            bulkActionStatusUpdateAppliedProducts?.length > 1
        ) {
            setApplyBulkActionClicked(false);
            let newList = productInitialData?.slice(0);
            newList = newList?.map((item) => {
                const found = bulkActionStatusUpdateAppliedProducts?.find((x) => x.id === item.id);
                if (found) {
                    item = found;
                }
                return item;
            });
            setProductInitialData(newList);
            if (bulkActionStatusUpdateAppliedProducts.length > 1) {
                if (bulkActionStatusUpdateAppliedProducts[0]?.itemStatus === ItemStatus.DELETED) {
                    setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_TRASHED);
                }

                if (
                    bulkActionStatusUpdateAppliedProducts[0]?.itemStatus === ItemStatus.UNPUBLISHED
                ) {
                    setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_RESTORE_ALL);
                }

                if (
                    bulkActionStatusUpdateAppliedProducts[0]?.itemStatus ===
                        ItemStatus.UNPUBLISHED &&
                    selectedProducts[0]?.itemStatus === ItemStatus.PUBLISHED
                ) {
                    setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_UNPUBLISHED);
                }

                if (
                    bulkActionStatusUpdateAppliedProducts[0]?.itemStatus === ItemStatus.PUBLISHED &&
                    selectedProducts[0]?.itemStatus === ItemStatus.UNPUBLISHED
                ) {
                    setMessage(SUCCESS_MESSAGE_BULK_ACTIONS_PUBLISHED);
                }
            }

            setShowToast(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkActionUpdateStatusMutationCompleted, bulkActionStatusUpdateAppliedProducts]);

    useEffect(() => {
        if (showToast === true && !IsDuplicate) {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [showToast, IsDuplicate]);

    useEffect(() => {
        const bulkActionList = [];
        const instantMenuList = [];
        if (tabStatus === ItemStatus.PUBLISHED) {
            bulkActionList.push({
                text: 'Move to Unpublish',
                value: BULK_ACTION_MOVE_TO_UNPUBLISHED,
            });
            instantMenuList.push(
                {
                    text: 'Edit',
                    value: INSTANT_EDIT_OPTION_EDIT,
                },
                {
                    text: 'View',
                    value: INSTANT_EDIT_OPTION_VIEW,
                },
                {
                    text: 'Unpublish',
                    value: INSTANT_EDIT_OPTION_UNPUBLISH,
                },
            );
        } else if (tabStatus === ItemStatus.UNPUBLISHED) {
            bulkActionList.push({
                text: 'Move to Publish',
                value: BULK_ACTION_MOVE_TO_PUBLISHED,
            });
            bulkActionList.push({
                text: 'Move to Trash',
                value: BULK_ACTION_SEND_TO_TRASH,
            });
            instantMenuList.push(
                {
                    text: 'Edit',
                    value: INSTANT_EDIT_OPTION_EDIT,
                },
                {
                    text: 'View',
                    value: INSTANT_EDIT_OPTION_VIEW,
                },
                {
                    text: 'Trash',
                    value: INSTANT_EDIT_OPTION_TRASH,
                },
                {
                    text: 'Publish',
                    value: INSTANT_EDIT_OPTION_PUBLISH,
                },
            );
        } else if (tabStatus === ItemStatus.DELETED) {
            bulkActionList.push({
                text: 'Restore all',
                value: BULK_ACTION_RESTORE_ALL,
            });
            instantMenuList.push(
                {
                    text: 'View',
                    value: INSTANT_EDIT_OPTION_VIEW,
                },
                {
                    text: 'Restore',
                    value: INSTANT_EDIT_OPTION_RESTORE,
                },
            );
        } else {
            bulkActionList.push({
                text: 'Move to Publish',
                value: BULK_ACTION_MOVE_TO_PUBLISHED,
            });
            bulkActionList.push({
                text: 'Move to Unpublish',
                value: BULK_ACTION_MOVE_TO_UNPUBLISHED,
            });
            instantMenuList.push(
                {
                    text: 'Edit',
                    value: INSTANT_EDIT_OPTION_EDIT,
                },
                {
                    text: 'View',
                    value: INSTANT_EDIT_OPTION_VIEW,
                },
                {
                    text: 'Publish',
                    value: INSTANT_EDIT_OPTION_PUBLISH,
                },
                {
                    text: 'Unpublish',
                    value: INSTANT_EDIT_OPTION_UNPUBLISH,
                },
            );
        }
        setBulkActionsMenuItems(bulkActionList);
        setInstantEditMenuItems(instantMenuList);
    }, [tabStatus, setBulkActionsMenuItems, setInstantEditMenuItems]);

    useEffect(() => {
        if (warningModalOkayClicked) {
            window.location.reload();
        }
    }, [warningModalOkayClicked]);

    const validateBulkActions = (action: string, items: any) => {
        const currentStatus =
            action === BULK_ACTION_MOVE_TO_PUBLISHED
                ? ItemStatus.UNPUBLISHED
                : ItemStatus.PUBLISHED;
        return !items?.some((item) => item.itemStatus !== currentStatus);
    };

    const validatePublish = (product: any) => {
        return product?.stock !== null;
    };

    const handleDuplicateProduct = (product: any) => {
        duplicateProduct({
            variables: {
                item: {
                    itemId: product?.itemId,
                    itemName: DUPLICATE_PRODUCT_PREFIX + product?.itemName,
                    itemType: product?.itemType,
                    itemStatus: ItemStatus.UNPUBLISHED,
                    itemImageUrl: product?.itemImageUrl,
                    itemGalleryUrls: product?.itemGalleryUrls,
                    itemDescBrief: product?.itemDescBrief,
                    itemDescDetail: product?.itemDescDetail,
                    deliveryMethod: product?.deliveryMethod,
                    isActive: product?.isActive,
                    isDeleted: product?.isDeleted,
                    createdBy: product?.createdBy,
                },
            },
        });
        setDuplicatedProduct(undefined);
        setSelectedMenuItem('');
    };

    const handleUpdateProductStatus = (product: any, status: any) => {
        updateProductStatus({
            variables: {
                item: {
                    id: product?.id,
                    itemId: product?.itemId,
                    itemName: product?.itemName,
                    itemType: product?.itemType,
                    itemStatus: status,
                    itemImageUrl: product?.itemImageUrl,
                    itemGalleryUrls: product?.itemGalleryUrls,
                    itemDescBrief: product?.itemDescBrief,
                    itemDescDetail: product?.itemDescDetail,
                    deliveryMethod: product?.deliveryMethod,
                    isActive: product?.isActive,
                    isDeleted: product?.isDeleted,
                    createdBy: product?.createdBy,
                    product: {
                        id: product?.product?.id,
                    },
                    currencyConversionRate: product?.currencyConversionRate,
                    unitPrice: product?.unitPrice,
                },
            },
        });
        setConfirmationModalYesClicked(false);
        setStatusUpdatedProduct(null);
        setSelectedMenuItem('');
    };

    const handleBulkAction = (idList: any, status: any) => {
        idList?.map((id) => {
            bulkActionUpdateStatus({
                variables: {
                    itemListInput: { itemId: id, itemStatus: status },
                },
            });
        });
    };

    const partnerList = partnerData
        ?.filter((partner) => partner.isActive)
        ?.map((partner) => ({
            cat: partner.id,
            key: partner.partnerName,
        }));

    function onSelectCatalogs(selectedList) {
        setIsSelectedPartnerRemoved(false);
        const selectedPartnerIds = selectedList?.map((list) => list?.cat);

        setSelectedPartnerId(selectedPartnerIds);
    }

    function onRemoveCatalogs(removedList) {
        const removedPartnerIds = removedList?.map((list) => list?.cat);
        const onListPartner = selectedPartnerId?.length
            ? selectedPartnerId?.filter((partner) =>
                  removedPartnerIds?.some((pat) => {
                      return pat === partner;
                  }),
              )
            : [];
        setSelectedPartnerId(onListPartner);
    }

    useEffect(() => {
        if (isCategoryProductsView) {
            const selectedProductsDetails = selectedProducts?.filter((prod) =>
                productInitialData?.some((initProd) => prod === initProd?.id),
            );
            let filtered = [];
            if (isCategoryProductsView && selectedTab === 1 && !viewMode) {
                setCheckboVisible(true);
                filtered = productInitialData;
                categoryProductsCounts.all = productInitialData?.length;
            } else if (
                isCategoryProductsView &&
                selectedTab === 2 &&
                !viewMode &&
                selectedTabProducts?.length
            ) {
                filtered = selectedTabProducts;
                setProductData(filtered);
                setCheckboVisible(false);
                categoryProductsCounts.selected = selectedTabProducts?.length;
            } else if (!selectedProducts?.length && isCategoryProductsView) {
                filtered = selectedProductsDetails;
                categoryProductsCounts.selected = 0;
                setProductData(null);
            } else if (
                viewMode &&
                selectedProducts?.length &&
                selectedTab === 2 &&
                isCategoryProductsView
            ) {
                filtered = selectedProductsDetails;
                categoryProductsCounts.selected = selectedProducts?.length;
            }

            setProductData(filtered);
        }
    }, [
        isCategoryProductsView,
        selectedTabProducts?.length,
        selectedTab,
        pageSize,
        pageIndex,
        viewMode,
        productInitialData,
        productInitialData?.length,
    ]);

    const handleApplyBulkActionBtnClick = () => {
        setApplyBulkActionClicked(true);
    };

    const productNames = [
        ...(productInitialData?.map((product) => product?.itemName) ?? []),
        ...(productData?.flatMap((product) => [
            product?.itemId,
            product?.product?.productCode ?? null,
            product?.product?.productId ?? null,
        ]) ?? []),
    ].filter((item) => item !== null && item !== undefined);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    useEffect(() => {
        if (selectedCategory?.length) {
            const category = categoryData?.filter((cate) => {
                if (cate?.id === selectedCategory) {
                    return cate?.categoryName;
                }
            });
            setSelectedCategoryName(category);
            if (!category?.length) {
                setSelectedSupplierName(null);
                setIsSelectedCategoryRemoved(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryData.length, selectedCategory]);

    useEffect(() => {
        if (selectedSupplier?.length) {
            const supplier = supplierData?.filter((sup) => {
                if (sup?.id === selectedSupplier?.toString()) {
                    return sup?.supplierName;
                }
            });
            setSelectedSupplierName(supplier);
            if (!supplier?.length) {
                setSelectedSupplierName(null);
                setIsSelectedSuppliersRemoved(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSupplier, supplierData?.length]);

    useEffect(() => {
        const partners = partnerData?.filter((partner) => {
            return selectedPartnerId?.some((pat) => {
                if (partner?.id === pat) {
                    return partner?.partnerName;
                }
            });
        });
        setSelectedPartnerNames(partners);
        if (!selectedPartnerNames?.length) {
            setIsSelectedPartnerRemoved(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPartnerId, partnerData?.length, selectedPartnerNames?.length]);

    const categoryProductsColumns = React.useMemo(
        () => [
            {
                Header: 'Product ID',
                accessor: 'itemId',
                fieldName: 'itemId',
            },
            {
                Header: 'Product Name',
                accessor: 'itemName',
                fieldName: 'itemName',
            },
            {
                Header: 'Product Origin',
                id: 'product.orderStrategy',
                accessor: (item) => {
                    if (item?.product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (item?.product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'product.productType',
            },
            {
                Header: 'Supplier',
                accessor: 'supplier.supplierName',
                fieldName: 'supplier.supplierName',
            },
        ],
        [],
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Product ID',
                accessor: 'itemId',
                fieldName: 'itemId',
            },
            {
                Header: 'Supplier Product Code',
                accessor: 'product',
                Cell: function showSupplierProductCode({ value }) {
                    const productCode = getSupplierProductCode(value);
                    return productCode;
                },
            },
            {
                Header: 'Product Name',
                accessor: 'itemName',
                fieldName: 'itemName',
                isProductHyperLink: true,
            },
            {
                Header: 'Product Origin',
                id: 'product.orderStrategy',
                accessor: (item) => {
                    if (item?.product?.orderStrategy === CSV_VOUCHERS) {
                        return CSV;
                    } else if (item?.product?.orderStrategy.startsWith('ALLSERV')) {
                        return ALLSERV;
                    } else {
                        return API;
                    }
                },
            },
            {
                Header: 'Product Type',
                accessor: 'product.productType',
            },
            {
                Header: 'Content Type',
                accessor: 'item.contentType',
                Cell: function contentTypeCell({ row }) {
                    return <span>{row?.original?.contentType}</span>;
                },
            },
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function sourceCurrencyCell({ row }) {
                    const value = row?.original?.product?.currency;
                    if (!value) return null;
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <span>{value}</span>
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'itemStatus',
                fieldName: 'itemStatus',
                Cell: function showBadge({ value }) {
                    const status =
                        value === ItemStatus.PUBLISHED
                            ? 'Published'
                            : value === ItemStatus.UNPUBLISHED
                            ? 'Unpublished'
                            : value === ItemStatus.DELETED
                            ? 'Trashed'
                            : '';
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                value === ItemStatus.PUBLISHED
                                    ? 'bg-green-100 text-green-600'
                                    : value === ItemStatus.UNPUBLISHED
                                    ? 'bg-gray-200 text-gray-600'
                                    : value === ItemStatus.DELETED
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
            {
                Header: 'Stocks Status',
                id: 'statusItem',
                accessor: 'isStockAvailable',
                fieldName: 'isStockAvailable',
                Cell: function showBadge({ value }) {
                    const status = value ? 'In Stock' : 'Out of Stock';
                    return (
                        <span
                            style={{
                                minWidth:
                                    status === 'In Stock'
                                        ? '72px'
                                        : status === 'Out of Stock'
                                        ? '96px'
                                        : '',
                            }}
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                status === 'In Stock'
                                    ? 'bg-yellow-100 text-yellow-600'
                                    : status === 'Out of Stock'
                                    ? 'bg-red-100 text-red-600'
                                    : ''
                            }`}
                        >
                            {status}
                        </span>
                    );
                },
            },
            // {
            //     Header: 'Points',
            //     accessor: 'supplier.id',
            //     Cell: function points({ value }) {
            //         const points = pointsDataQuery?.globalPointsList?.filter(
            //             (point) => point?.supplier?.id === value,
            //         );

            //         return <span>{points?.map((point) => point.overriddenValue)}</span>;
            //     },
            // },  needed in future

            {
                Header: 'Available Stock/Total Stock',
                id: 'stockCount',
                accessor: '',
                Cell: function points({ row }) {
                    return row?.original?.product?.orderStrategy === CSV_VOUCHERS ? (
                        <span
                            id={`${row?.original?.itemId}-${row?.original?.product?.availableStock}/${row?.original?.product?.totalStock}`}
                        >{`${row?.original?.product?.availableStock}/${row?.original?.product?.totalStock}`}</span>
                    ) : (
                        <span id={`${row?.original?.itemId}`}>{`-/-`}</span>
                    );
                },
            },
            {
                Header: 'Product Group',
                id: 'productGroup',
                accessor: 'itemProductGroup.productGroup.name',
            },
            // {
            //     Header: 'Categories',
            //     id: 'categories',
            //     accessor: 'itemCatalogCategory',
            //     fieldName: 'itemCatalogCategory',
            //     width: 10,
            //     Cell: function Categories({ value, row }) {
            //         const dataArray = [];
            //         value
            //             ?.filter((cat) => cat?.item?.itemId === row?.values?.itemId)
            //             .map((data) => dataArray?.push(data?.category?.categoryName));
            //         const set = new Set(dataArray);
            //         const uniqueSetValues = [...set];

            //         return (
            //             <span
            //                 id={`${row?.original?.itemId}-${uniqueSetValues?.length}`}
            //                 className="text-gray-400"
            //             >
            //                 {JSON.stringify(uniqueSetValues).replace(/[\[\]'"]+/g, ' ')}
            //             </span>
            //         );
            //     },
            // },
            {
                Header: 'Supplier',
                accessor: 'supplier.supplierName',
                fieldName: 'supplier.supplierName',
            },
            {
                id: 'actionColumn',
                accessor: '',
                fieldName: '',
                disableSortBy: true,
                width: 5,
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2 text-xl"
                            id="product_edit_options"
                        >
                            <BsThreeDots className="mx-auto" />
                        </button>
                    </div>
                ),
            },
            {
                id: 'expander',
                accessor: '',
                fieldName: '',
                disableSortBy: true,
                width: 5,
                // eslint-disable-next-line react/display-name
                Cell: ({ row, rows, toggleRowExpanded }) => (
                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                            onClick: () => {
                                const expandedRow = rows.find((row) => row.isExpanded);

                                if (expandedRow) {
                                    const isSubItemOfRow = Boolean(
                                        expandedRow && row.id.split('.')[0] === expandedRow.id,
                                    );

                                    if (isSubItemOfRow) {
                                        const expandedSubItem = expandedRow.subRows.find(
                                            (subRow) => subRow.isExpanded,
                                        );

                                        if (expandedSubItem) {
                                            const isClickedOnExpandedSubItem =
                                                expandedSubItem.id === row.id;
                                            if (!isClickedOnExpandedSubItem) {
                                                toggleRowExpanded(expandedSubItem.id, false);
                                            }
                                        }
                                    } else {
                                        toggleRowExpanded(expandedRow.id, false);
                                    }
                                }
                                row.toggleRowExpanded();
                            },
                        })}
                        id={
                            row.isExpanded
                                ? `${row?.original?.itemId}-product-quick-edit-close`
                                : `${row?.original?.itemId}-product-quick-edit-open`
                        }
                    >
                        {row.isExpanded ? 'X' : '▼'}
                    </span>
                ),
            },
        ],
        [],
    );

    return (
        <>
            {showToast && (
                <div className="mt-3 ml-1">
                    <Toast
                        setShowToast={setShowToast}
                        error={toastError}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                    />
                </div>
            )}
            <ConfirmationModalWithMessage
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                message={confirmationMessage}
                messageTitle={confirmationMessageTitle}
                setYesBtnClick={setConfirmationModalYesClicked}
                setNoBtnClick={setConfirmationModalNoClicked}
            />
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={warningModalBody}
                warningModalTitle={warningModalTitle}
                setProcessFinishedClicked={setWarningModalOkayClicked}
            />
            <NoPartnerModal
                isModalOpen={isNoPartnerModalOpen}
                setModalOpen={setIsNoPartnerModalOpen}
            />
            <div className="border-2 border-gray-300 rounded-lg mr-5">
                {isCategoryProductsView ? (
                    <div className="font-poppins text-lg px-5 pt-3 font-bold"> Product list</div>
                ) : null}
                <div className={`w-full ml-4 ${!isCategoryProductsView ? 'pt-4' : null} `}>
                    {!isCategoryProductsView ? (
                        <TableTabView
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            productCounts={productStatus}
                        />
                    ) : (
                        <>
                            {viewMode ? (
                                <TableTabView
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    categoryProductsCounts={categoryProductsCounts}
                                    isViewMode={viewMode}
                                />
                            ) : (
                                <TableTabView
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    categoryProductsCounts={categoryProductsCounts}
                                />
                            )}
                        </>
                    )}
                </div>
                <div
                    className={`flex flex-row w-full px-4    ${
                        !isCategoryProductsView ? 'py-4' : 'pt-1 pb-4'
                    }`}
                >
                    {!isCategoryProductsView && (
                        <div className="flex realative lg:mr-4">
                            <button
                                onKeyDown={() => {
                                    setIsFilterClicked(!isFilterClicked);
                                    setSelectedPartnerNames(null);
                                }}
                                onClick={() => {
                                    setIsFilterClicked(!isFilterClicked);
                                    setSelectedPartnerNames(null);
                                    if (!isFilterClicked) {
                                        setSelectedSupplier(null);
                                        setSelectedProductType(null);
                                        setSelectedPartner(null);
                                        setSelectedCategory(null);
                                        setSelectedCategoryName(null);
                                        setSelectedStockType(null);
                                        setIsSelectedCategoryRemoved(true);
                                        setIsSelectedCategoryRemoved(true);
                                        setIsSelectedSuppliersRemoved(true);
                                        setIsSelectedProductRemoved(true);
                                        setIsSelectedPartnerRemoved(true);
                                        setIsSelectedStockRemoved(true);
                                        setSelectedSupplierName(null);
                                        setSelectedContentType(null);
                                        setIsSelectedContentRemoved(true);
                                    }
                                }}
                                id="product_filter"
                                className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                    isFilterClicked ? 'bg-purple-500 text-white' : 'text-gray-500'
                                }`}
                            >
                                <span className="flex">
                                    <BsFillFunnelFill /> <span className="ml-2">Filter</span>
                                </span>
                            </button>
                        </div>
                    )}
                    {!isCategoryProductsView && (
                        <div
                            className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500"
                            id="product_search"
                        >
                            <SearchBar
                                id="product-list-search-field"
                                options={productNames}
                                handleChange={(e, newVal, reason) => {
                                    setSearchClicked(true);
                                    handleChange(e, newVal, reason);
                                }}
                                placeHolder={'Search Product by Name, ID or Product Code'}
                            />
                        </div>
                    )}
                    {showBulkActions && !isCategoryProductsView && (
                        <div className="flex flex-row relative space-x-2 px-2">
                            <select
                                className="pl-2 pr-8 z-0 rounded-md outline-none border font-poppins bg-white border-gray-400 hover:border-purple-500"
                                id="product_bulkAction"
                                placeholder="actions"
                                onChange={(e) => {
                                    setBulkAction(e.target.value);
                                }}
                                onBlur={(e) => setBulkAction(e.target.value)}
                            >
                                <option
                                    value="select"
                                    key="select"
                                    className="py-1 rounded-md"
                                    id="product_bulkAction"
                                    disabled={true}
                                >
                                    Select Action
                                </option>
                                {bulkActionsMenuItems.map((option: any, index) => {
                                    return (
                                        <option value={option.value} key={index} className="py-2">
                                            {option.text}
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                className="bg-purple-500 text-white rounded-md px-8 font-poppins  text-center font-bold"
                                onClick={() => {
                                    if (bulkAction.length === 0) {
                                        setBulkAction(bulkActionsMenuItems[0].value);
                                    }
                                    handleApplyBulkActionBtnClick();
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    )}
                    {!isCategoryProductsView && (
                        <div className="relative flex flex-row flex-1 justify-end right-0">
                            <button
                                className="bg-purple-500 text-white rounded-md px-8 font-poppins text-center font-bold"
                                onClick={() => {
                                    if (
                                        partnerData.filter((partner) => partner.isActive).length > 0
                                    ) {
                                        setIsDuplicate(false);
                                        history.push('/products/inventory-add');
                                    } else {
                                        setIsNoPartnerModalOpen(true);
                                    }
                                }}
                                id="Add_product_button"
                                onBlur={() => {
                                    if (
                                        partnerData.filter((partner) => partner.isActive).length > 0
                                    ) {
                                        setIsDuplicate(false);
                                        history.push('/products/inventory-add');
                                    } else {
                                        setIsNoPartnerModalOpen(true);
                                    }
                                }}
                            >
                                Add New Product
                            </button>
                        </div>
                    )}
                </div>
                {isFilterClicked && !isCategoryProductsView && (
                    <>
                        <div className="flex h-24 gap-2 p-2 w-full">
                            <div className="w-full">
                                <div className="flex flex-wrap flex-col relative">
                                    <div className="text-gray-600 w-32 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Filter by category
                                    </div>
                                    <select
                                        className="px-4 py-3 z-0 rounded-lg outline-none border bg-white border-gray-400 hover:border-purple-500"
                                        id="filter_category"
                                        placeholder="actions"
                                        onChange={(e) => {
                                            setSelectedCategory(e.target.value);
                                            setIsSelectedCategoryRemoved(false);
                                        }}
                                        disabled={categoriesDropDownList.length <= 0}
                                    >
                                        {isSelectedCategoryRemoved ? (
                                            <option
                                                value={null}
                                                disabled
                                                selected
                                                id="filter_category"
                                                className={'font-medium'}
                                            >
                                                Select category filters
                                            </option>
                                        ) : null}

                                        {categoriesDropDownList.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className={'font-medium'}
                                                    key={index}
                                                >
                                                    {option.text?.length > 32
                                                        ? `${option.text?.slice(0, 32)}...`
                                                        : option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="w-full ">
                                <div className="flex flex-wrap flex-col relative">
                                    <div className="text-gray-600 w-32 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Filter by supplier
                                    </div>
                                    <select
                                        className="px-4 py-3 z-0 rounded-lg outline-none border bg-white border-gray-400 hover:border-purple-500"
                                        id="filter_supplier"
                                        placeholder="actions"
                                        onChange={(e) => {
                                            setSelectedSupplier(e.target.value);
                                            setIsSelectedSuppliersRemoved(false);
                                        }}
                                        disabled={suppliersDropDownList.length <= 0}
                                    >
                                        {isSelectedSupplierRemoved ? (
                                            <option
                                                value={null}
                                                disabled
                                                selected
                                                id="filter_supplier"
                                            >
                                                Select supplier filters
                                            </option>
                                        ) : null}
                                        {suppliersDropDownList.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className={'font-medium'}
                                                    key={index}
                                                >
                                                    {option.text?.length > 32
                                                        ? `${option.text?.slice(0, 32)}...`
                                                        : option.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="flex flex-wrap flex-col relative">
                                    <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Filter by product type
                                    </div>
                                    <select
                                        className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                        id="filter_product_type"
                                        placeholder="actions"
                                        onChange={(e) => {
                                            setSelectedProductType(e.target.value);
                                            setIsSelectedProductRemoved(false);
                                        }}
                                    >
                                        {isSelectedProductRemoved ? (
                                            <option
                                                value={null}
                                                disabled
                                                selected
                                                id="filter_product_type"
                                            >
                                                Select product type filters
                                            </option>
                                        ) : null}
                                        {productTypeOptions.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className={'font-medium'}
                                                    key={index}
                                                >
                                                    {option.text.slice(0, 13)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="flex flex-wrap flex-col relative">
                                    <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Filter by stock type
                                    </div>
                                    <select
                                        className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                        id="stockStatus"
                                        placeholder="actions"
                                        onChange={(e) => {
                                            setSelectedStockType(e.target.value);
                                            setIsSelectedStockRemoved(false);
                                        }}
                                    >
                                        {isSelectedStockRemoved ? (
                                            <option value={null} disabled selected>
                                                Select stock type filters
                                            </option>
                                        ) : null}
                                        {stockTypeOptions.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className={'font-medium'}
                                                    key={index}
                                                >
                                                    {option.text.slice(0, 13)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex h-24 gap-2 p-2 w-1/2">
                            <div className="w-full flex flex-wrap flex-col relative">
                                <div className="text-gray-600 w-32 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                    Filter by partner
                                </div>
                                <Multiselect
                                    displayValue="key"
                                    id="filter_partner"
                                    style={{
                                        chips: {
                                            background: 'purple',
                                        },
                                        multiselectContainer: {
                                            color: 'black',
                                        },
                                        searchBox: {
                                            border: '1px solid gray',
                                            'border-radius': '6px',
                                            padding: '10px',
                                        },
                                    }}
                                    options={partnerList}
                                    onSelect={onSelectCatalogs}
                                    onRemove={onRemoveCatalogs}
                                    showCheckbox
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex flex-wrap flex-col relative">
                                    <div className="text-gray-600 w-40 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                        Filter by content type
                                    </div>
                                    <select
                                        className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                        id="stockStatus"
                                        placeholder="actions"
                                        onChange={(e) => {
                                            setSelectedContentType(e.target.value);
                                            setIsSelectedContentRemoved(false);
                                        }}
                                    >
                                        {isSelectedContentRemoved ? (
                                            <option value={null} disabled selected>
                                                Select an option
                                            </option>
                                        ) : null}
                                        {contentTypeOptions.map((option: any, index) => {
                                            return (
                                                <option
                                                    value={option.value}
                                                    className={'font-medium'}
                                                    key={index}
                                                >
                                                    {option.text.slice(0, 13)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {isFilterClicked &&
                (!isSelectedCategoryRemoved ||
                    !isSelectedSupplierRemoved ||
                    !isSelectedPartnerRemoved ||
                    !isSelectedProductRemoved ||
                    !isSelectedContentRemoved ||
                    !isSelectedStockRemoved) ? (
                    <div className="bg-purple-100 w-full h-20 flex py-2 px-4 my-5 ">
                        <div className="w-full -mt-2">
                            <div className="flex flex-col">
                                <div className="text-purple-500 font-poppins  flex justify-between p-5 -mt-1">
                                    <div className="flex">
                                        {selectedCategoryName?.length ? (
                                            <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                                <div className="font-poppins mt-1 mr-3">
                                                    {selectedCategoryName[0]?.categoryName}
                                                </div>
                                                <button
                                                    className="text-purple-700 font-bold  text-lg"
                                                    onClick={() => {
                                                        setSelectedCategory(null);
                                                        setSelectedCategoryName(null);
                                                        setIsSelectedCategoryRemoved(true);
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        ) : null}

                                        {selectedSupplierName?.length && isFilterClicked ? (
                                            <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                                <div className="font-poppins mt-1 mr-3">
                                                    {selectedSupplierName[0]?.supplierName}
                                                </div>
                                                <button
                                                    className="text-purple-700 font-bold  text-lg"
                                                    onClick={() => {
                                                        setSelectedSupplier(null);
                                                        setIsSelectedSuppliersRemoved(true);
                                                        setSelectedSupplierName(null);
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        ) : null}
                                        {selectedPartnerNames?.length && isFilterClicked
                                            ? selectedPartnerNames?.map((partner) => (
                                                  <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                                      <div className="font-poppins mt-1 mr-3">
                                                          {partner?.partnerName}
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                        {selectedProductType && isFilterClicked ? (
                                            <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                                <div className="font-poppins mt-1 mr-3">
                                                    {selectedProductType}
                                                </div>
                                                <button
                                                    className="text-purple-700 font-bold  text-lg"
                                                    onClick={() => {
                                                        setSelectedProductType(null);
                                                        setIsSelectedProductRemoved(true);
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        ) : null}
                                        {selectedStockType && isFilterClicked ? (
                                            <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                                <div className="font-poppins mt-1 mr-3">
                                                    {selectedStockType === 'true'
                                                        ? IN_STOCK
                                                        : OUT_OF_STOCK}
                                                </div>
                                                <button
                                                    className="text-purple-700 font-bold  text-lg"
                                                    onClick={() => {
                                                        setSelectedStockType(null);
                                                        setIsSelectedStockRemoved(true);
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        ) : null}

                                        {selectedContentType && isFilterClicked ? (
                                            <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                                <div className="font-poppins mt-1 mr-3">
                                                    {selectedContentType}
                                                </div>
                                                <button
                                                    className="text-purple-700 font-bold  text-lg"
                                                    onClick={() => {
                                                        setSelectedContentType(null);
                                                        setIsSelectedContentRemoved(true);
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>
                                    {!isSelectedCategoryRemoved ||
                                    !isSelectedSupplierRemoved ||
                                    !isSelectedPartnerRemoved ||
                                    !isSelectedProductRemoved ||
                                    !isSelectedContentRemoved ||
                                    !isSelectedStockRemoved ? (
                                        <div className="w-auto h-auto p-3 border-l-2 border-gray-300">
                                            <div className="right-0 my-auto  mx-10 border-l-2 flex underline text-lg">
                                                <button
                                                    className="text-purple-400 font-bold  text-lg"
                                                    onClick={() => {
                                                        setSelectedSupplier(null);
                                                        setSelectedProductType(null);
                                                        setSelectedPartner(null);
                                                        setSelectedCategory(null);
                                                        setSelectedCategoryName(null);
                                                        setSelectedStockType(null);
                                                        setIsSelectedCategoryRemoved(true);
                                                        setIsSelectedCategoryRemoved(true);
                                                        setIsSelectedSuppliersRemoved(true);
                                                        setIsSelectedProductRemoved(true);
                                                        setIsSelectedPartnerRemoved(true);
                                                        setIsSelectedStockRemoved(true);
                                                        setSelectedSupplierName(null);
                                                        setIsSelectedContentRemoved(true);
                                                        setSelectedContentType(null);
                                                    }}
                                                >
                                                    Clear all
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                <div className="w-full">
                    {productData?.length || loading ? (
                        <DataTable
                            columns={isCategoryProductsView ? categoryProductsColumns : columns}
                            data={productData}
                            selectedDefaultItems={productData[3]}
                            pageCount={pageCount} //future usage
                            setSelectedItems={setSelectedProducts}
                            tabStatus={tabStatus}
                            setPageIndex={setPageIndex} //future usage
                            serverSidePagination={true}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSizes={pageSize}
                            loading={loading}
                            // customizedPageSize={Math.ceil(totalItems / totalPages)}
                            totalItems={totalItems}
                            setDefaultPageSize={setPageSize}
                            menuItems={instantEditMenuItems}
                            setSelectedMenuItem={setSelectedMenuItem}
                            setExposeMenuClickedItem={setClickedProduct}
                            showReorderTooltip={showReorderTooltip}
                            checkbox={checkboxVisible}
                            checkboxHeader={checkboxVisible}
                            radioBtn={false}
                            selectedTab={selectedTab}
                            setSortState={setSortState}
                            setSortField={setSortField}
                            isInitialLoad={isInitialLoad}
                            setIsInitialLoad={setIsInitialLoad}
                        />
                    ) : isCategoryProductsView && !selectedProduct?.length ? (
                        <div className="m-auto flex flex-col ">
                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-black font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No selected products.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    ) : isFilterClicked && !productData?.length ? (
                        <div className="m-auto flex flex-col ">
                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-black font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">
                                No data found for the applied filters.
                            </div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    ) : searchClicked && !productData?.length ? (
                        <div className="m-auto flex flex-col ">
                            <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-black font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10 flex">
                                Your search
                                <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                did not match any results
                            </div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    ) : (
                        <div className="m-auto flex flex-col ">
                            <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                {columns?.map((column, index) => {
                                    return (
                                        <div
                                            className="text-gray-600 font-poppins font-semibold flex ml-12"
                                            key={index}
                                        >
                                            {column?.Header}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="m-auto p-10">No data found.</div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
