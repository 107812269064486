import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_DEAL_LOG_BY_ID } from '../../../../queries/DealQueries';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import DataTable from '../../../ui/organisms/DataTable';

function DealLogTable(props: { dealId: number }) {
    const [pageCount, setPageCount] = useState(null);
    const [pageIndex, setPageIndex] = useState(null);
    const [pageSize, setPageSize] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const dealID = props.dealId;

    const {
        data: dealLogData,
        loading: dealLogDataLoading,
        error: dealLogQueryError,
    } = useQuery(FETCH_DEAL_LOG_BY_ID, {
        variables: {
            dealId: dealID,
            offset: currentPage - 1 || 0,
            limit: Math.ceil(pageSize) || 10000,
        },
        context: { clientName: ClientsEnum.STORE },
        nextFetchPolicy: 'cache-and-network',
        fetchPolicy: 'network-only',
    });

    const columns = useMemo(
        () => [
            { id: 'date', Header: 'Date', accessor: 'createdDate' },
            { id: 'itemId', Header: 'Product ID', accessor: 'itemId' },
            { id: 'productName', Header: 'Product Name', accessor: 'itemName' },
            { id: 'description', Header: 'Description', accessor: 'description' },
            { id: 'initiatedBy', Header: 'Initiated By', accessor: 'initiatedBy' },
        ],
        [],
    );

    return (
        <div>
            <div className="text-lg font-poppins font-bold pt-33 px-8  py-8">Deal Logs:</div>
            <div className="border-2 border-gray-300 rounded-lg ml-10 mt-7 mr-7 mb-10">
                {dealLogData && !dealLogDataLoading && dealLogData.dealLog.length ? (
                    <DataTable
                        columns={columns}
                        data={dealLogData.dealLog}
                        serverSidePagination={true}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex}
                        setDefaultPageSize={setPageSize}
                        currentPage={currentPage}
                        loading={dealLogDataLoading}
                        setCurrentPage={setCurrentPage}
                        pageSizes={pageSize}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column) => {
                                return (
                                    <div
                                        key={column.id}
                                        className="text-black font-poppins font-semibold flex ml-12"
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default DealLogTable;
