import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of your form data
export interface DealFormData {
    dealName: string;
    percentage: number;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    roundBoundary: string;
    decimalPlaces: string;
    selectedPartners: Set<string>;
    selectedPaymentMethods: string[];
    selectedPaymentChannels: any[];
    selectedItems: string[];
}

// Define the context value type
interface DealFormContextType {
    formData: DealFormData;
    updateFormData: (newData: Partial<DealFormData>) => void;
    updateSelectedPartners: (partnerId: string) => void;
}

// Create the context
const DealFormContext = createContext<DealFormContextType | undefined>(undefined);

export const DealFormProvider = ({ children }: { children: ReactNode }) => {
    const [formData, setFormData] = useState<DealFormData>({
        dealName: '',
        percentage: null,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        roundBoundary: '',
        decimalPlaces: '',
        selectedPartners: new Set<string>(),
        selectedPaymentMethods: [],
        selectedPaymentChannels: [],
        selectedItems: [],
    });

    const updateFormData = (newData: Partial<DealFormData>) => {
        setFormData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    const updateSelectedPartners = (partnerId: string) => {
        setFormData((prevData) => {
            const updatedPartners = new Set(prevData.selectedPartners);
            if (updatedPartners.has(partnerId)) {
                updatedPartners.delete(partnerId);
            } else {
                updatedPartners.add(partnerId);
            }
            return {
                ...prevData,
                selectedPartners: updatedPartners,
            };
        });
    };

    return (
        <DealFormContext.Provider value={{ formData, updateFormData, updateSelectedPartners }}>
            {children}
        </DealFormContext.Provider>
    );
};

// Custom hook for accessing the context
export const useDealForm = (): DealFormContextType => {
    const context = useContext(DealFormContext);
    if (!context) {
        throw new Error('useDealForm must be used within a DealFormProvider');
    }
    return context;
};
