import { useState, useMemo, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { BsThreeDots } from 'react-icons/bs';
import { PaymentMethod } from '../../../../enums/payment';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_SELECTED_PAYMENT_CHANNELS_BY_DEAL_ID } from '../../../../queries/DealQueries';
import DataTable from '../../../ui/organisms/DataTable';
import { useDispatch } from 'react-redux';
import { saveDealId } from '../../../../redux/rootActions';
import Loader from '../../../../utils/loader';
import SearchBar from '../../../ui/molecules/SearchBar';
import { DealPaymentChannelsDto } from '../../../../types/deals';

export default function ViewDealSelectedPaymentChannels() {
    const dispatch = useDispatch();
    const parameters = useParams();
    const dealID = parameters['id'];
    // TODO: partner IDs should come as a prop from the previous stage.

    const [selectedPaymentChannels, setSelectedPaymentChannels] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [SELECTED_PAYMENT_METHODS, setSelectedPaymentMethods] = useState([]);

    const { data, loading: paymentChannelsLoading } = useQuery(
        FETCH_SELECTED_PAYMENT_CHANNELS_BY_DEAL_ID,
        {
            variables: { dealId: dealID },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
            onCompleted: () => {
                if (data != null) {
                    setSelectedPaymentChannels(data?.dealSelectedPaymentChannels?.paymentChannels);
                    setFilteredData(data?.dealSelectedPaymentChannels?.paymentChannels);
                    const paymentMethods = getSelectedPaymentMethods(
                        data?.dealSelectedPaymentChannels,
                    );
                    setSelectedPaymentMethods(paymentMethods);
                }
            },
        },
    );

    useEffect(() => {
        dispatch(saveDealId(dealID));
    }, []);

    useEffect(() => {
        if (searchTerm?.length > 0) {
            const filtered = selectedPaymentChannels?.filter((p) =>
                p.paymentMethod.toLowerCase().includes(searchTerm.toLowerCase()),
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(selectedPaymentChannels);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm?.length) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false);
        }
    }, [searchTerm?.length]);

    const handleChange = (e, newVal, reason) => {
        if (reason == 'input' || reason == 'reset') {
            setSearchTerm(newVal);
        }
    };

    const paymentMethod = selectedPaymentChannels?.map((channel) => channel?.paymentMethod);

    const paymentChannelDataArray = paymentMethod != null ? [...paymentMethod] : [];

    const getSelectedPaymentMethods = (paymentMethodDetails: DealPaymentChannelsDto): any => {
        const paymentMethodsArray = [];

        if (paymentMethodDetails != null) {
            if (paymentMethodDetails?.isDcbSelected) {
                paymentMethodsArray.push(PaymentMethod.DCB);
            }

            if (paymentMethodDetails?.isPspSelected) {
                paymentMethodsArray.push(PaymentMethod.PSP);
            }

            if (paymentMethodDetails?.isPointsSelected) {
                paymentMethodsArray.push(PaymentMethod.POINTS);
            }
        }
        return paymentMethodsArray;
    };

    const columns = useMemo(
        () => [
            { userId: 'id', Header: 'Channel ID', accessor: 'channelId' },

            {
                id: 'name',
                Header: 'Name',
                accessor: 'channelName',
                Cell: function productGroupCell({ row }) {
                    return (
                        <Link
                            className="hover:underline"
                            rel="noreferrer"
                            target="_blank"
                            to={`/payment-channels/view/${row?.original?.id}`}
                        >
                            {row?.original?.channelName}
                        </Link>
                    );
                },
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            },
            {
                id: 'method',
                Header: 'Method',
                accessor: 'paymentMethod',
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: 'isActive',
                Cell: function paymentStatus({ value }) {
                    if (value) {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-green-100 text-green-600">
                                Active
                            </span>
                        );
                    } else {
                        return (
                            <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-red-100 text-red-600">
                                Inactive
                            </span>
                        );
                    }
                },
            },
        ],
        [],
    );

    return (
        <div>
            <div className="text-lg font-poppins font-bold px-8 ml-3">Payment Methods</div>
            <div className="flex mt-5 pb-5 ml-7">
                {SELECTED_PAYMENT_METHODS.map((paymentMethod) => (
                    <div key={paymentMethod} className="flex mx-5 items-baseline">
                        <input
                            type="checkbox"
                            value={PaymentMethod[paymentMethod]}
                            checked={true}
                        />
                        <label className="ml-2">{paymentMethod}</label>
                    </div>
                ))}
            </div>

            <div className="text-lg font-poppins font-bold px-8 ml-3">Payment Channels</div>
            <div className="border-2 border-gray-300 rounded-lg ml-10 mt-7 mr-7 mb-10">
                <div className="flex p-4">
                    <div className="flex w-full">
                        <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3">
                            <SearchBar
                                id="partner-list-search-field"
                                options={paymentChannelDataArray}
                                handleChange={(e, newVal, reason) => {
                                    handleChange(e, newVal, reason);
                                }}
                                placeHolder={'Search Payment Method'}
                            />
                        </div>
                    </div>
                </div>
                {paymentChannelsLoading ? (
                    <div className="w-full">
                        <div className="m-auto flex flex-col ">
                            <div className="m-auto p-10">
                                <Loader />
                            </div>
                            <div className="p-10 bg-purple-100"></div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full">
                        {filteredData?.length ? (
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                checkbox={false}
                                hidePagination={true}
                            />
                        ) : searchClicked && !filteredData?.length ? (
                            <div className="m-auto flex flex-col ">
                                <div className="py-3 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, index) => {
                                        return (
                                            <div
                                                className="text-black font-poppins font-semibold flex ml-12"
                                                key={index}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10 flex">
                                    Your search
                                    <div className="text-purple-500 mx-2">{`"${searchTerm}"`}</div>
                                    did not match any results
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        ) : (
                            <div className="m-auto flex flex-col ">
                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                    {columns?.map((column, i) => {
                                        return (
                                            <div
                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                key={i}
                                            >
                                                {column?.Header}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="m-auto p-10">No data found.</div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
