export function getTimeFromUtcDatetime(utcDatetime: string) {
    const date = new Date(utcDatetime);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export function getDateFromUTCDateTime(utcDatetime: string) {
    const dateObject = new Date(utcDatetime);
    return dateObject.toISOString().split('T')[0];
}

export function viewDateTimeUTC(utcDatetime: string) {
    const date = getDateFromUTCDateTime(utcDatetime);
    const time = getTimeFromUtcDatetime(utcDatetime);

    const [hours, minutes] = time.split(':').map(Number);
    if (
        isNaN(hours) ||
        isNaN(minutes) ||
        hours < 0 ||
        hours >= 24 ||
        minutes < 0 ||
        minutes >= 60
    ) {
        throw new Error('Invalid time format');
    }

    // Determine AM or PM and adjust hours for 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, '0');

    const timeConverted = `${adjustedHours}:${formattedMinutes} ${ampm}`;

    return date + ' ' + timeConverted + ' UTC';
}

export function combineDateAndTime(dateUTC: string, timeUTC: string) {
    // Combine date and time into a single string
    const dateTimeString = `${dateUTC}T${timeUTC}Z`;

    // Create a Date object from the combined string
    const date = new Date(dateTimeString);

    // Convert the Date object to a UTC string
    const utcString = date.toISOString();
    return utcString;
}

export function getDealStatusValidation(deals) {
    if (deals?.length) {
        const dealNames: string[] = [];

        // Get the current date and time in UTC
        const nowUTC = new Date().toISOString();

        deals.forEach((deal) => {
            // Convert endDateTime to Date object
            const dealEndDateTime = new Date(deal?.endDateTime).toISOString();

            // Check if the deal end date and time is greater than or equal to the current UTC date and time
            if (dealEndDateTime >= nowUTC) {
                if (!dealNames.includes(deal?.dealName)) {
                    dealNames.push(deal?.dealName || 'Unknown Deal');
                }
            }
        });

        return dealNames.length > 0 ? dealNames : null;
    } else {
        return null;
    }
}
