export const INSTANT_EDIT_OPTION_EDIT_DEAL = 'EDIT';

export const SUCCESS_MESSAGE_SAVED_DEAL = 'Deal added successfully';

export const ERROR_ADD_DEAL = 'Error occurred while creating the deal';

export const DEAL_ADD_SUCCESS_TITLE = 'Success!';

export const DEAL_ADD_SUCCESS_BODY = 'Successfully added a deal.';

export const DEAL_EDIT_SUCCESS = 'Deal details updated successfully !.';

export const ERROR_EDIT_DEAL = 'Error occurred while editing the deal';

export const DEAL_SELECTED_PARTNERS_TABLE_TYPE = 'DEAL_SELECTED_PARTNERS';

export const DEAL_TIME_OVELAPPING_ERROR_MSG =
    'Item assigned to this deal is already assigned to another deal for the selected time range. Please adjust the time range to update this deal';

export const DEAL_VALIDATION_ERROR_TITLE = 'Error';

export const DEAL_ITEM_VALIDATION_ERROR_BODY =
    'This item cannot be inactivated/trashed as it is currently assigned to an active deal.';
